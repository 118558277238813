import { Box, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import ProductViewCard from "./ProductViewCard";

const ProductViewSection = ({ view, productList }) => {
    return (
        <Box marginY={2}>
            <Grid container columnSpacing={2} >
                {
                    productList.length > 0
                        ? productList.map((product) => (
                            <Fragment key={product.id}>
                                <ProductViewCard
                                    product={product}
                                    view={view}
                                />
                            </Fragment>
                        ))
                        :
                        <Grid item sx={{ textAlign: "center" }} xs={12}>
                            <Typography variant="h6">暫時找不到商品</Typography>
                        </Grid>
                }
            </Grid>
        </Box>
    )
}


export default ProductViewSection;