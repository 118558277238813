import { Container, Skeleton } from "@mui/material";

const SkeletonForm = () => {
    return (
        <Container>
            <Skeleton variant="rectangular" width="200px" height="20px" sx={{ marginY: 2 }} />
            <Skeleton variant="rectangular" width="100%" height="40px" sx={{ marginY: 2 }} />
            <Skeleton variant="rectangular" width="50px" height="20px" sx={{ marginY: 2 }} />
            <Skeleton variant="rectangular" width="100%" height="40px" sx={{ marginY: 2 }} />
            <Skeleton variant="rectangular" width="50px" height="20px" sx={{ marginY: 2 }} />
            <Skeleton variant="rectangular" width="100%" height="40px" sx={{ marginY: 2 }} />
            <Skeleton variant="rectangular" width="50px" height="20px" sx={{ marginY: 2 }} />
            <Skeleton variant="rectangular" width="100%" height="40px" sx={{ marginY: 2 }} />
            <Skeleton variant="rectangular" width="50px" height="20px" sx={{ marginY: 2 }} />
            <Skeleton variant="rectangular" width="100%" height="40px" sx={{ marginY: 2 }} />
            <Skeleton variant="rectangular" width="100%" height="25px" sx={{ marginY: 2 }} />

        </Container>
    )
}

export default SkeletonForm;