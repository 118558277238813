import { Box, Container } from "@mui/material";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmEmailForm from "../../component/form/confirmEmail/ConfirmEmailForm";
const ConfirmEmail = () => {
    const state = useLocation().state;
    const navigate = useNavigate();
    useEffect(() => {
        if (!state) {
            navigate('/');
        }
    }, [state, navigate])
    return (
        <Container maxWidth="xs" sx={{ background: "white" }}>
            <Box sx={{ paddingY: 4 }}>
                <ConfirmEmailForm email={state} />
            </Box>
        </Container>
    )
}

export default ConfirmEmail;