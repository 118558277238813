import { createSlice } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist-indexeddb-storage';

const initialState = {
    isLoading: false,
    isLogin: false,
    error: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoginRequest: (state, action) => {
            state.isLoading = true;
            state.error = null;
            state.isLogin = false;
        },
        setLoginSuccess: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.isLogin = true;
        },
        setLoginFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            state.isLogin = false;
        },
        logout: (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.isLogin = false;
        },
    },
});

const authPersistConfig = {
    key: 'auth',
    storage: storage('marketDB'),
    expires: {
        aith: 1000 * 60 * 24
    },
};

export const authPersist = persistReducer(authPersistConfig, authSlice.reducer);

export const { setLoginRequest, setLoginSuccess, setLoginFailure, logout } = authSlice.actions;
export default authPersist;