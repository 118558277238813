
import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Button } from '@mui/material';
import { createSearchParams, useNavigate } from 'react-router-dom';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    borderRadius: '999px',
    display: 'flex',
}));

const SearchIconWrapper = styled(Button)(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: ' 0px 999px 999px 0px',
    '&:hover': {
        backgroundColor: "#00000020",
    },
    color: "black"
}));


const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    width: '100%',
    '& .MuiInputBase-input': {
        [theme.breakpoints.up('xs')]: {
            height: '16px',
            padding: theme.spacing(1, 2, 1, 2),

        },
        [theme.breakpoints.up('md')]: {
            height: '36px',
            padding: theme.spacing(1, 4, 1, 4),
        },
        // vertical padding + font size from searchIcon
        '&:focus': {
            outlineStyle: 'solid',
            outlineWidth: '4px',
            outlineColor: "#00a0c820",
            borderRadius: '999px 0px 0px 999px',
        },
    },
}));


const AppBarSearch = () => {
    const [keyword, setKeyword] = React.useState("");
    const navigate = useNavigate();
    const handleClick = React.useCallback(() => {
        navigate({
            pathname: "/product",
            search: createSearchParams({ page: 1, keyword }).toString()
        });
    }, [navigate, keyword]);
    return (
        <Search
            flexGrow={1}
        >
            <StyledInputBase
                placeholder="搜索…"
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e) => { setKeyword(e.target.value) }}
            />
            <SearchIconWrapper onClick={handleClick}>
                <SearchIcon />
            </SearchIconWrapper>
        </Search>
    )
}

export default AppBarSearch;