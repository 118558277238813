import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const CustomHoverSlideBox = styled(Box)(({ theme }) => ({
    overflowX: "auto",
    scrollbarWidth: "none", // Firefox
    msOverflowStyle: "none", // Internet Explorer 10+
    WebkitOverflowScrolling: "touch", // iOS momentum scrolling

    // Hide scrollbar for Chrome, Safari and Opera
    "&::-webkit-scrollbar": {
        display: "none",
    },

    // Mobile devices: always show a thin scrollbar
    [theme.breakpoints.down('sm')]: {
        "&::-webkit-scrollbar": {
            display: "block",
            height: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.grey[400],
            borderRadius: "2px",
        },
        scrollbarWidth: "thin", // Firefox
    },

    // Desktop: show scrollbar on hover
    [theme.breakpoints.up('md')]: {
        "&:hover": {
            "&::-webkit-scrollbar": {
                display: "block",
                height: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.grey[400],
                borderRadius: "4px",
            },
            scrollbarWidth: "thin", // Firefox
        },
    },
}));

export default CustomHoverSlideBox;