import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { AppBar, Box, Container, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { NavLink } from "react-router-dom"
import AppBarShopCenter from "../AppBarShopCenter"
const MenuBar = () => {
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    }

    return (
        <AppBar
            sx={{
                position: 'relative',
                top: 0,
                backgroundColor: "white",
                boxShadow: "0 4px 4px -4px gray",
            }}>
            <Container maxWidth="lg">
                <Box
                    display={{ xs: 'none', md: 'block' }}>
                    <Stack
                        display={"flex"}
                        direction="row"
                        spacing={2}
                        padding={2}>
                        <NavLink
                            style={{
                                display: "block",
                                color: "#00a0c8",
                                textDecoration: "none",
                                textAlign: "center"
                            }}
                            to="/">
                            主頁
                        </NavLink>

                        <Box
                            sx={{
                                cursor: "pointer"
                            }}
                            onClick={handleClick}
                            display="flex"
                            alignItems="center"
                            color="#00a0c8">
                            <Typography variant="body1">研究社商城</Typography>
                            {
                                open
                                    ? <ExpandLess fontSize="small" sx={{ marginLeft: 0 }} />
                                    : <ExpandMore fontSize="small" sx={{ marginLeft: 0 }} />
                            }
                        </Box>
                        <NavLink
                            style={{
                                display: "block",
                                color: "#00a0c8",
                                textDecoration: "none",
                                textAlign: "center"
                            }}
                            to="/product">
                            限時推介🐚
                        </NavLink>

                        <Box>
                            <NavLink
                                style={{
                                    display: "block",
                                    width: "100%",
                                    color: "#00a0c8",
                                    textDecoration: "none",
                                    textAlign: "center"
                                }}
                                to="/return-policy">
                                退換貨政策
                            </NavLink>
                        </Box>

                        <Box>
                            <NavLink
                                style={{
                                    display: "block",
                                    width: "100%",
                                    color: "#00a0c8",
                                    textDecoration: "none",
                                    textAlign: "center"
                                }}
                                to="/membership">
                                會員制度
                            </NavLink>
                        </Box>

                        <Box>
                            <NavLink
                                style={{
                                    display: "block",
                                    width: "100%",
                                    color: "#00a0c8",
                                    textDecoration: "none",
                                    textAlign: "center"
                                }}
                                to="/">
                                聯絡我們
                            </NavLink>
                        </Box>
                    </Stack >
                    {open && <AppBarShopCenter />}
                </Box>
            </Container>
        </AppBar>
    )
}

export default MenuBar