import { Fragment } from "react";
import ReturmPolicyBanner from "./component/ReturnPolicyBanner";
import ReturnPolicyTerm from "./component/ReturnTerm";

const ReturnTerm = () => {
    return (
        <Fragment>
            <ReturmPolicyBanner />
            <ReturnPolicyTerm />
        </Fragment>
    )
}

export default ReturnTerm;