import { Box, Button, Grid, Typography } from "@mui/material";
import { useSnackbar } from 'notistack';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ImageWithSkeleton from "../../../component/image/ImageSkeleton";
import { addToCart } from "../../../redux/slices/cart/slicer";
import CurrencyText from "../../../component/text/CurrencyText";
const HomeProductCard = ({ product }) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleAddToCart = () => {
        dispatch(addToCart({ ...product, quantity: 1 }));
        enqueueSnackbar("",
            {
                variant: "product",
                item: { ...product },
                navigate: navigate,
                autoHideDuration: 2000
            }
        )
    }
    return (
        <Grid
            container
            item xs={6} md={3}>
            <Grid
                item
                container
                xs={12}
                direction={"row"}
                alignContent={"space-between"}
                justifyContent={"space-between"}
            >
                <Grid
                    item
                    xs={12}
                >
                    <ImageWithSkeleton
                        width="100%"
                        aspectRatio={1 / 1}
                        imageUrl={product.imageUrl}
                    />
                    <Box
                        sx={{
                            marginY: 1,
                            display: "block",
                        }}
                    >
                        <CurrencyText
                            variant="body1"
                            fontWeight={"bold"}
                            amount={parseFloat(product.price).toFixed(2)}
                        />
                        <Typography
                            variant="body2"
                        >
                            {product.name}
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            display: "block",
                            width: "100%"
                        }}
                        onClick={handleAddToCart}
                    >
                        加入購物車
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}


export default HomeProductCard
