import { Typography } from '@mui/material';
import React from 'react';

const CurrencyText = ({ amount, variant = "body1", fontWeight = "400", textAlign = "left", preText = "" }) => {
    const formattedAmount = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'HKD',
    }).format(amount);

    return (
        <Typography
            variant={variant}
            fontWeight={fontWeight}
            textAlign={textAlign}
        >
            {preText}{formattedAmount}
        </Typography>);
};

export default CurrencyText;