import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';
const initialState = {
    items: [],
    total: 0,
    discount: 0,
    count: 0,
    payment_method: null,
    detail: {
        name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        postal_code: '',
        country: '',
    },
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state, action) => {
            const item = action.payload;
            const index = state.items.findIndex((i) => i.id === item.id);
            if (index === -1) {
                state.items.push(item);
                state.count++;
            } else {
                state.items[index].quantity += item.quantity;
            }
            state.total += item.price * item.quantity;
            state.discount += item.discount ?? 0;
        },
        removeFromCart: (state, action) => {
            //ask if it is really confirm
            const confirm = window.confirm('Are you sure to remove this item from cart?');
            if (!confirm) {
                return;
            }
            const item = action.payload;
            const index = state.items.findIndex((i) => i.id === item.id);
            if (index !== -1) {
                state.items.splice(index, 1);
            }
            state.total = state.items.reduce((acc, item) => acc + item.price * item.quantity, 0);
            state.count = state.items.length;
            state.discount = state.items.reduce((acc, item) => acc + (item.discount ?? 0), 0);
        },
        updateFromCart: (state, action) => {
            const { id, quantity } = action.payload;
            // check type of quantity
            if (typeof quantity !== 'number') {
                return;
            }
            // check type of id
            if (typeof id !== 'number') {
                return;
            }

            const index = state.items.findIndex((i) => i.id === id);
            //first update quantity
            if (index !== -1) {
                if (quantity <= 0) {
                    const confirm = window.confirm('Are you sure to remove this item from cart?');
                    if (!confirm) {
                        return;
                    }
                    state.items.splice(index, 1);
                } else {
                    state.items[index].quantity = quantity;
                }
            }
            //then re-calculate total, count, discount  
            state.total = state.items.reduce((acc, item) => acc + item.price * item.quantity, 0);
            state.count = state.items.length;
            state.discount = state.items.reduce((acc, item) => acc + (item.discount ?? 0), 0);

        },
        clearCart: (state) => {
            state.items = [];
            state.total = 0;
            state.count = 0;
            state.discount = 0;
        },
        setPaymentMethod: (state, action) => {
            state.payment_method = action.payload;
        },
        setDetail: (state, action) => {
            state.detail = action.payload;
        }
    },
});

const cartPersistConfig = {
    key: 'cart',
    storage: storage('marketDB'),
    expires: {
        cart: 1000 * 60 * 24
    },
};

const persistedReducer = persistReducer(cartPersistConfig, cartSlice.reducer);

export const { setDetail, setPaymentMethod, clearCart, removeFromCart, addToCart, updateFromCart } = cartSlice.actions;
export default persistedReducer;