import { Box } from "@mui/material";
import { NavLink } from "react-router-dom";
import ImageWithSkeleton from "../../../component/image/ImageSkeleton";

const AppLogo = () => {
    return (
        <Box
            flexGrow={1}
            margin={1}
            maxHeight={{
                xs: "80px",
            }}
            display="flex"
            alignItems="center"
        >

            <NavLink
                to="/"
            >
                <ImageWithSkeleton
                    needSkeleton={false}
                    imageUrl="/logo.webp"
                    height={{
                        xs: "60px",
                        md: "60px",
                        xl: "80px",
                    }}
                    aspectRatio={"3.075"}
                />
            </NavLink>
        </Box >
    )
}

export default AppLogo;