import { configureStore } from '@reduxjs/toolkit';
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE
} from 'redux-persist';
import logger from './middleware/logger';
import authReducer from './slices/auth/slicer';
import cartReducer from './slices/cart/slicer';
import headerReducer from './slices/header/slicer';
import modalReducer from './slices/modal/slicer';
import ordersReducer from './slices/orders/slicer';
import productReducer from './slices/product/slicer';
import settingReducer from './slices/setting/slicer';
import userReducer from './slices/user/slicer';

const store = configureStore({
    reducer: {
        header: headerReducer,
        auth: authReducer,
        orders: ordersReducer,
        user: userReducer,
        product: productReducer,
        setting: settingReducer,
        modal: modalReducer,
        cart: cartReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }).concat(logger),
});


const persistor = persistStore(store);
export { persistor, store };
