import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Box, Typography, styled } from "@mui/material";
import Badge from '@mui/material/Badge';
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";


const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: "#00a0c8",
    },
    width: '36px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: "pointer"

}));

const AppBarCart = () => {
    const cartCount = useSelector(state => state.cart.count);
    return (
        <NavLink
            to="/cart"
            style={{
                textDecoration: "none",
                color: "black"
            }}
        >
            <Box
                cursor="pointer"
                margin={1}
                padding={{
                    md: "4px 8px",
                    xs: "0px"
                }}
                sx={{
                    cursor: "pointer",
                    display: "flex",
                    "&:hover": {
                        backgroundColor: "#f5f5f5",
                    },
                    borderRadius: {
                        xs: '999px',
                        md: '12px'
                    }
                }}
                alignItems="center"
            >



                <StyledBadge
                    sx={{
                        backgroundColor: {
                            sx: "#ffffff",
                            md: "#f6f6f6"
                        },
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: "#e5e5e5",
                        borderRadius: "999px"
                    }}
                    badgeContent={cartCount}
                    color="secondary">
                    <ShoppingCartIcon
                        fontSize="8px"
                    />
                </StyledBadge >
                <Typography
                    fontSize={12}
                    marginLeft={1}
                    noWrap
                    sx={{ display: { xs: 'none', md: 'block' } }}
                    fontWeight={600}
                    color="textSecondary">我的購物車
                </Typography>
            </Box >
        </NavLink>
    );
}

export default AppBarCart;