import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import useFetchUserProfile from "../../../hooks/useUserProfile";
import { useDispatch } from "react-redux";
import { setProfileState } from "../../../redux/slices/user/slicer";
import { useNavigate } from "react-router-dom";
import SkeletonForm from "../../skeleton/SkeletonForm";

const validationSchema = Yup.object().shape({
    email: Yup.string().email('電郵格式不正確').required('必須'),
    lastName: Yup.string().required('必須'),
    firstName: Yup.string().required('必須'),
    address: Yup.string().optional(),
    phoneNumber: Yup.string().matches(/^[0-9]{8}$/, '電話號碼格式不正確').optional(),
    gender: Yup.string().required('必須')
})

const ProfileEditForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useFetchUserProfile();
    const handleFormSubmit = (values) => {
        dispatch(setProfileState(values));
        navigate('/profile');
    }

    return (
        <>
            {
                user.isFetched
                    ?
                    <Formik
                        initialValues={user}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                    >
                        {
                            ({ values, touched, errors, handleChange, handleBlur, isSubmitting, handleSubmit }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Typography variant="h6" sx={{ mb: 2 }}>更改個人資料</Typography>
                                    <TextField
                                        value={values.email}
                                        label="電郵"
                                        variant="outlined"
                                        name="email"
                                        fullWidth
                                        onChange={handleChange}
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                        sx={{ my: 1 }}
                                        disabled
                                    />

                                    <TextField
                                        value={values.lastName}
                                        label="姓"
                                        variant="outlined"
                                        name="lastName"
                                        fullWidth
                                        onChange={handleChange}
                                        error={touched.lastName && Boolean(errors.lastName)}
                                        helperText={touched.lastName && errors.lastName}
                                        sx={{ my: 1 }}
                                    />

                                    <TextField
                                        value={values.firstName}
                                        label="名"
                                        variant="outlined"
                                        name="firstName"
                                        fullWidth
                                        onChange={handleChange}
                                        error={touched.firstName && Boolean(errors.firstName)}
                                        helperText={touched.firstName && errors.firstName}
                                        sx={{ my: 1 }}
                                    />

                                    <TextField
                                        value={values.address}
                                        label="地址"
                                        variant="outlined"
                                        name="address"
                                        fullWidth
                                        onChange={handleChange}
                                        error={touched.address && Boolean(errors.address)}
                                        helperText={touched.address && errors.address}
                                        sx={{ my: 1 }}
                                    />

                                    <TextField
                                        value={values.phoneNumber}
                                        label="電話"
                                        variant="outlined"
                                        name="phoneNumber"
                                        fullWidth
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 8)
                                        }}
                                        onChange={handleChange}
                                        error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                                        helperText={touched.phoneNumber && errors.phoneNumber}
                                        sx={{ my: 1 }}
                                    />

                                    <FormControl fullWidth sx={{ my: 1 }}>
                                        <InputLabel id="gender-label">性別</InputLabel>
                                        <Select
                                            labelId='gender-label'
                                            value={values.gender}
                                            name='gender'
                                            label="性別"
                                            fullWidth
                                            required
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={Boolean(touched.gender && errors.gender)}
                                            sx={{ mb: 2 }}
                                        >
                                            <MenuItem value={'F'}>女性</MenuItem>
                                            <MenuItem value={'M'}>男性</MenuItem>
                                            <MenuItem value={'O'}>其他</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <Box marginY={2} display={"block"}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            disabled={isSubmitting}
                                        >
                                            送出
                                        </Button>
                                    </Box>
                                </Form>
                            )
                        }
                    </Formik >
                    :
                    <SkeletonForm />
            }
        </>
    );

}

export default ProfileEditForm;