import { Box, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import CartProductCard from "./CartProductCard";
import CartTotalBoard from "./CartTotalBoard";
const CartProductSection = ({ cart }) => {
    return (
        <Fragment>
            <Box>
                <Typography variant={"h4"}>
                    訂單概覽
                </Typography>
                {
                    cart.items.length === 0 ?
                        (
                            <Box
                                sx={{
                                    padding: 3,
                                    marginY: 2,
                                    backgroundColor: "#d1ecf1",
                                    borderColor: "#bee5eb",
                                    color: "#0e616e",
                                    borderRadius: "8px",
                                }}
                            >
                                <Typography variant={"body1"}>
                                    購物車是空的
                                </Typography>
                            </Box>
                        )
                        :
                        (
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    md={8}
                                    padding={2}
                                >
                                    {
                                        cart.items.map((item) => {
                                            return (
                                                <CartProductCard
                                                    key={item.id}
                                                    product={item}
                                                />
                                            )
                                        })
                                    }
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={4}>
                                    <CartTotalBoard cart={cart} />
                                </Grid>
                            </Grid>
                        )
                }
            </Box >
        </Fragment >
    )
}

export default CartProductSection;