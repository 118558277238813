
import { Box, styled } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from "react-redux";
import { toggleDrawer } from "../../../redux/slices/modal/slicer";

const MyStyledButton = styled(`div`)(({ theme }) => ({
    display: 'flex',
    // Add your custom styles here
    padding: theme.spacing(1),
    fontSize: theme.typography.pxToRem(16),
    boxShadow: 'none',
    backgroundColor: "none",
    color: 'black',
    cursor: 'pointer',
    ":active": {
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineColor: "#00a0c8",
    }
}));
const AppBarBurger = () => {

    const dispatch = useDispatch();
    const handleBurgerClick = () => {
        dispatch(toggleDrawer());
    }
    return (
        <Box
            onClick={() => handleBurgerClick()}
            sx={{
                display: { xs: 'block', md: 'none' }
            }}>
            <MyStyledButton>
                <MenuIcon />
            </MyStyledButton>
        </Box>

    )
}

export default AppBarBurger;