import { SnackbarContent } from 'notistack';
import HomeSnackbar from '../../pages/Home/componet/HomeSnackbar';
import { forwardRef } from 'react';


const CustomeSnackbar = forwardRef(
    ({ item, navigate }, ref) => {
        return (
            <SnackbarContent
                style={{
                    justifyContent: "flex-end"
                }}
                ref={ref}
            >
                <HomeSnackbar
                    item={item}
                    navigate={navigate}
                />
            </SnackbarContent>
        );
    });

export default CustomeSnackbar
