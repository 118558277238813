import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import HomeSnackbarCard from "./HomeSnackbarCard";


const HomeSnackbar = ({ item, navigate }) => {

    return (
        <Stack
            sx={{
                color: "black",
                backgroundColor: "white",
                display: "block",
                maxWidth: "400px",
                width: "80vw",
                borderRadius: "8px",
                boxShadow: "0px 0px 8px 0px #0000001a",
            }}
        >
            <Box padding={2}>
                <Typography variant={"body2"}>
                    項目已加入至你的購物車
                </Typography>
            </Box>

            <Divider />

            <HomeSnackbarCard
                name={item.name}
                image={item.image}
                price={item.price}
                imageUrl={item.imageUrl}
            />

            <Box
                sx={{
                    display: "flex"
                }}
            >
                <Button
                    sx={{ width: "100%", }}
                    onClick={() => { navigate("/cart") }}
                >
                    查看購物車
                </Button>
            </Box>

        </Stack>
    );
}

export default HomeSnackbar;