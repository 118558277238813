import { Formik, Form } from "formik";
import { Box, styled, TextField, Typography } from "@mui/material";
import * as Yup from "yup";
import validateCodeApi from "../../../api/action/validateCodeApi";
import useResendCode from "../../../hooks/useResendCode";

const CustomStyledText = styled(Typography)(({ theme }) => ({
    fontSize: "0.875rem",
    cursor: "pointer",
    color: "#007390",
    '&:hover': {
        textDecoration: 'underline',
    }
}));

const initialValues = {
    code: '',
}

const validationSchema = Yup.object().shape({
    code: Yup.string().required('驗證碼必填').matches(/^[0-9]{6,6}$/, '驗證碼格式錯誤'),
})

const ConfirmEmailForm = ({ email }) => {
    const { countdown, resend, canResend } = useResendCode();
    const handleFormSubmit = async (values, { setSubmitting, setFieldError }) => {
        // navigate('/confirm/email');
        try {
            console.log(values);
            if (!await validateCodeApi(values)) {
                return;
            }
        } catch (e) {
            console.log(e);
        } finally {
            setSubmitting(false);
        }
    }
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
        >
            {({
                touched,
                errors,
                handleChange,
                isSubmitting,
                submitForm
            }) => (
                <Form>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        我們已發送驗證碼至您的電子郵件, 請輸入驗證碼以完成註冊
                    </Typography>
                    <TextField
                        label="驗證碼"
                        variant="outlined"
                        name="code"
                        fullWidth
                        required
                        type='tel'
                        onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
                        }}
                        onChange={(e) => {
                            if (e.target.value.length === 6 && !isSubmitting) {
                                handleChange(e);
                                submitForm();
                            }
                        }}
                        error={Boolean(touched.code && errors.code)}
                        helperText={touched.code && errors.code}
                        sx={{ mb: 2 }}
                    />
                    {canResend ?
                        <Box onClick={resend}>
                            <CustomStyledText variant="body2">
                                重新發送驗證碼
                            </CustomStyledText>
                        </Box>
                        :
                        <Typography variant="body2" color={"#c0c0c0"}>
                            {countdown}秒後重新發送
                        </Typography>
                    }
                </Form>
            )}
        </Formik>
    )
};

export default ConfirmEmailForm;