import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux/slices/auth/slicer";

const useLogout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleLogout = () => {
        dispatch(logout());
        sessionStorage.clear();
        navigate('/login');
    };

    return { handleLogout };
}

export default useLogout;