import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const StyledComponet = styled(NavLink)(({ theme }) => ({
  color: '#007390',
  textDecoration: 'none',
  transition: 'background-color 0.3s ease',

  '&:hover': {
    textDecoration: 'underline',
  },

  '&.active': {
    fontWeight: 'bold',
    color: '#000',
  },
}));

const StyledNavLink = ({ children, to }) => {
  return <StyledComponet to={to}>{children}</StyledComponet>;
};


export default StyledNavLink;