import React, { Fragment } from "react";
import HomeArticleSection from "./componet/HomeArticleSection";
import HomeBanner from "./componet/HomeBanner";
import HomeIntroduction from "./componet/HomeIntroduction";
import HomeProductSection from "./componet/HomeProductSection";

const HomePage = () => {

    return (
        <Fragment>
            <HomeBanner />
            <HomeProductSection />
            <HomeIntroduction />
            <HomeArticleSection />
        </Fragment>
    )
}

export default HomePage;