import ImageWithSkeleton from "../../../component/image/ImageSkeleton";


const HomeBanner = () => {
    return (
        <ImageWithSkeleton
            imageUrl={"/banner_home.jpg"}
            width="100%"
            height="520px"
            objectFit="cover"
        />
    );
};

export default HomeBanner;