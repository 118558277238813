import { Container } from "@mui/material";
import CartStepper from "./component/CartStepper";
import CartProductSection from "./component/CartProductSection";
import { useSearchParams } from "react-router-dom";
import useTitleHooks from "../../hooks/useTitleHooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const CartPage = () => {
    useTitleHooks("訂單結帳");
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page") ? parseInt(searchParams.get("page")) : 0;
    const cart = useSelector((state) => state.cart);
    useEffect(() => {
        if (!cart || cart.items.length === 0) {
            setSearchParams({ page: 0 });
        }
    }, [page, setSearchParams, cart]);
    return (
        <Container maxWidth="lg">
            <CartStepper setSearchParams={setSearchParams} stage={page ? parseInt(page) : 0} />
            {page === 0 && <CartProductSection cart={cart} />}
        </Container >
    );
};

export default CartPage;