import { Box, Grid, Typography } from "@mui/material";
import ImageWithSkeleton from "../../../component/image/ImageSkeleton";
import CurrencyText from "../../../component/text/CurrencyText";

const ProductViewCard = ({ product, view }) => {
    return (
        <Grid
            container
            item
            xs={view === 'module' ? 6 : 12}
            md={view === 'module' ? 3 : 12}
        >
            <Box
                display="flex"
                flexDirection={view === "module" ? "column" : "row"}
                borderBottom={"2px solid #f0f0f0"}
                paddingY={2}
                width={"100%"}
            >
                <Box
                    alignItems={"center"}
                    display="flex"
                >
                    <ImageWithSkeleton
                        width="100%"
                        maxWidth={view === 'module' ? "100%" : "126px"}
                        aspectRatio={1 / 1}
                        imageUrl={product.imageUrl}
                    />
                </Box>
                <Box
                    paddingX={view === 'module' ? 0 : 2}
                    flex={1}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                    }}
                >
                    <Box
                        paddingY={view === 'module' ? 1 : 0}
                    >
                        <Typography
                            whiteSpace={"pre-wrap"}
                            color="#00A0CB"
                            variant="body1"
                            fontWeight={"bold"}>
                            {product.name}
                        </Typography>
                        <Typography
                            whiteSpace={"pre-wrap"}
                            display={view === 'module' ? 'none' : 'block'}
                            variant="body2"
                            color={"grey"}
                        >
                            {product.description}
                        </Typography>
                    </Box>
                    <CurrencyText
                        variant="body1"
                        fontWeight={"bold"}
                        amount={parseFloat(product.price).toFixed(2)}
                    />
                </Box>
            </Box>
        </Grid>
    )
};

export default ProductViewCard;