import { Box, Container, Typography } from "@mui/material"
import VideoSkeleton from "../../../component/video/videoSkeleton";

const text = `由社長創辦。本人自小遊走於各區街市，由愛好開始，認識、飼養魚類及品嚐各種海鮮。\n\n至數年前開始，開始拍攝及紀錄香港街市的實況，在影片及討論區中介紹食材特色及不同食法。 香港街市可以說是魚類海鮮的寶庫，四季皆有不同時令的漁貨，魚類海鮮不僅種類繁多，烹飪方法也是多種多樣。每種海鮮都有其適合的烹飪方式，能夠最大程度地保留鮮味的同時，發揮出意想不到的美味。\n\n本人希望通過此網購平台，將世界各地的優質漁貨及新鮮食品帶到大眾的餐桌。透過產地直接供貨，減省在運輸中因多重運輸及買賣期間食材鮮度下降的情況， 確保每一份食材都是以最新鮮的狀態呈現給大家。我們不僅提供美味，更希望傳遞一種對生活的熱愛和對美食的追求。我們的目標是將品牌理念年輕化和大眾化，讓每一位消費者都能享受到我們精心挑選的食品。`

const HomeIntroduction = () => {
    return (
        <Container maxWidth="lg">
            <Box sx={{ width: "100%" }}>
                <Typography sx={{ whiteSpace: 'pre-line' }} marginY={4}>
                    <strong>香港街市魚類海鮮研究社</strong>{text}
                </Typography>
                <VideoSkeleton videoId={"Qahsno808cQ?si=4B0JjOrFagz-zoyx"} />
            </Box>
        </Container>
    );
};

export default HomeIntroduction;