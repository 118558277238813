import { Box, Container, Paper, Stack, styled, Typography } from "@mui/material"
import LoginForm from "../../component/form/login/LoginForm";
import { NavLink } from 'react-router-dom';

const CustomStyledLink = styled(NavLink)(({ theme }) => ({
    textDecoration: 'none',
    fontSize: "0.875rem",
    color: "#007390",
    '&:hover': {
        textDecoration: 'underline',
    }
}));

const LoginPage = () => {
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
        }}>
            <Container maxWidth="xs" sx={{ marginY: 4 }}>
                <Paper
                    component={Box}
                    paddingX={4}
                    paddingY={8}
                    elevation={4}
                    sx={{
                        alignSelf: "center",
                        borderRadius: "24px"
                    }}
                >
                    <Stack spacing={2}>
                        <Typography variant="h5" fontWeight={"bold"}>
                            登入
                        </Typography>
                        <Typography>
                            請輸入您的帳號密碼
                        </Typography>
                        <LoginForm />
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            marginTop={2}
                        >
                            <CustomStyledLink to="/register">還沒有帳戶？</CustomStyledLink>
                            <CustomStyledLink to="/forget-password">忘記密碼</CustomStyledLink>
                        </Box>
                    </Stack>
                </Paper>
            </Container>
        </Box>
    )
}

export default LoginPage;