import { Box, Step, StepButton, Stepper } from '@mui/material';
import * as React from 'react';


const steps = [
    "訂購商品",
    "送貨",
    "付款"
];

export default function CartStepper({ stage, setSearchParams }) {
    const handleClicked = (index) => {
        setSearchParams({ page: index });
    }
    return (
        <Box padding={"24px 0"}>
            <Stepper
                activeStep={stage}>
                {steps.map((label, index) => (

                    <Step
                        active={stage === index}
                        completed={stage > index}
                        key={index}>
                        <StepButton onClick={() => { handleClicked(index); }}>{label}</StepButton >
                    </Step>

                ))}
            </Stepper>
        </Box>
    )
}


