import { useState, useEffect, useCallback } from 'react';

const useResendCode = (initialTime = 60) => {
    const [countdown, setCountdown] = useState(0);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        let interval = null;
        if (isActive && countdown > 0) {
            interval = setInterval(() => {
                setCountdown(seconds => seconds - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsActive(false);
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, countdown]);

    const startCountdown = useCallback(() => {
        setCountdown(initialTime);
        setIsActive(true);
    }, [initialTime]);

    const resend = useCallback(() => {
        // Implement your email resend logic here
        console.log('Resending email...');
        // For example, you might call an API here
        // await api.resendVerificationEmail();

        startCountdown();
    }, [startCountdown]);

    return {
        countdown,
        isActive,
        resend,
        canResend: !isActive && countdown === 0
    };
};

export default useResendCode;