import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import StyledNavLink from '../../button/StyledNavLink';
import isEmailExistApi from '../../../api/action/isEmailExistApi';

const initialValues = {
    email: '',
    password: '',
    repassword: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    gender: '',
    birth: new Date(),
}

const validationSchema = Yup.object().shape({
    email: Yup.string().email('電子郵件格式錯誤').required('*必須填寫'),
    password: Yup.string().required('*必須填寫').min(6, '密碼長度至少6位').max(20, '密碼長度至多20位').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,20}$/, '密碼必須包含大小寫字母和數字'),
    repassword: Yup.string().required('*必須填寫').oneOf([Yup.ref('password'), null], '密碼不一致'),
    firstName: Yup.string().required('*必須填寫'),
    lastName: Yup.string().required('*必須填寫'),
    phoneNumber: Yup.string().optional().matches(/^[0-9]{8,8}$/, '電話號碼格式錯誤'),
    gender: Yup.string().required('*必須填寫'),
    birth: Yup.string().required('*必須填寫'),
});

const RegisterForm = () => {
    const navigate = useNavigate();
    const handleFormSubmit = async (values, { setSubmitting, setFieldError }) => {
        // navigate('/confirm/email');
        try {
            if (await isEmailExistApi(values.email)) {
                setFieldError('email', '此電郵已被註冊');
                return;
            }

            navigate('/confirm/email', { state: values.email });
        } catch (e) {
            console.log(e);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
        >
            {({
                handleSubmit,
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                isSubmitting
            }) => (
                <Form onSubmit={handleSubmit}>
                    <Typography variant='h6' textAlign={"left"} sx={{ mb: 2 }}>
                        註冊成為會員
                    </Typography>
                    <TextField
                        label="姓氏"
                        variant="outlined"
                        name="lastName"
                        fullWidth
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                        sx={{ mb: 2 }}
                    />

                    <TextField
                        label="名字"
                        variant="outlined"
                        name="firstName"
                        fullWidth
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                        sx={{ mb: 2 }}
                    />

                    <TextField
                        label="電郵"
                        variant="outlined"
                        name="email"
                        fullWidth
                        autoComplete='username'
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="密碼"
                        variant="outlined"
                        name="password"
                        type="password"
                        fullWidth
                        required
                        autoComplete='new-password'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="確認密碼"
                        variant="outlined"
                        name="repassword"
                        autoComplete='new-password'
                        type="password"
                        fullWidth
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.repassword && errors.repassword)}
                        helperText={touched.repassword && errors.repassword}
                        sx={{ mb: 2 }}
                    />

                    <TextField
                        label="電話號碼"
                        variant="outlined"
                        type='tel'
                        name="phoneNumber"
                        placeholder='電話號碼(以作訂單聯絡作用)'
                        onInput={(e) => {
                            //only allows number input
                            if (e.target.value) {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }
                            if (e.target.value.length > 8) {
                                e.target.value = e.target.value.slice(0, 8)
                            }
                        }}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                        helperText={touched.phoneNumber && errors.phoneNumber}
                        sx={{ mb: 2 }}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="gender-label">性別</InputLabel>
                        <Select
                            labelId='gender-label'
                            value={values.gender}
                            name='gender'
                            label="性別"
                            fullWidth
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.gender && errors.gender)}
                            sx={{ mb: 2 }}
                        >
                            <MenuItem value={'F'}>女性</MenuItem>
                            <MenuItem value={'M'}>男性</MenuItem>
                            <MenuItem value={'O'}>其他</MenuItem>
                        </Select>
                    </FormControl>
                    <Box marginY={2} display={"block"}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={isSubmitting}
                        >
                            註冊
                        </Button>
                    </Box>
                    <StyledNavLink to="/login" style={{ textDecoration: 'none', color: 'black' }}>
                        <Typography textAlign={"center"} variant='body2' marginY={2}>
                            已經有帳號？點擊這裡登入
                        </Typography>
                    </StyledNavLink>
                </Form>
            )}
        </Formik>
    );
};


export default RegisterForm;