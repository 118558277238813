import { Button, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { setLoginRequest, setLoginSuccess } from '../../../redux/slices/auth/slicer';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('電子郵件格式錯誤')
        .required('*必須填寫'),
    password: Yup.string().required('*必須填寫'),
});

const LoginForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleFormSubmit = () => {
        dispatch(setLoginRequest({ isLoading: true }));
        dispatch(setLoginSuccess({ isLoading: false }));
        navigate('/');
    }

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
        >
            {({
                handleSubmit,
                touched,
                errors,
                handleChange,
                handleBlur,
                isSubmitting
            }) => (
                <Form onSubmit={handleSubmit}>
                    <TextField
                        label="電郵"
                        variant="outlined"
                        name="email"
                        fullWidth
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="密碼"
                        variant="outlined"
                        name="password"
                        type="password"
                        fullWidth
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        sx={{ mb: 2 }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={isSubmitting}
                    >
                        登入
                    </Button>
                </Form>
            )}
        </Formik>
    );
};


export default LoginForm;