import { Box, Paper } from "@mui/material";

const OrderDetailPaymentSection = () => {
    return (
        <Box sx={{ marginY: 2 }}>
            <Paper variant="outlined" sx={{ paddingX: 2 }}>
                Payment Section

            </Paper>
        </Box>
    )
}

export default OrderDetailPaymentSection;