import { Box, Container, Stack, Typography } from "@mui/material"

const Footer = () => {
    return (
        <Box
            backgroundColor="#0078A0"
            paddingY={5}
            color={"white"}
        >
            <Container maxWidth="lg">
                <Stack
                    spacing={2}>
                    <Typography variant="h6">
                        關於研究社
                    </Typography>
                    <Typography variant="body1">
                        本社長為魚痴一名，總之有關魚嘅野都啱，對魚類及各種海鮮嘅食味同樣貌可以話過目不忘。 本社希望透過此網購平台，為大家搜羅各地新鮮優質食品，分享美味。
                    </Typography>
                </Stack>
            </Container>
        </Box>
    )
}

export default Footer;