import { Box, Divider, IconButton, List, ListItem, ListItemButton, styled, Typography } from "@mui/material"
import AppBarSearch from "../AppBarSearch";
import CloseIcon from '@mui/icons-material/Close';
import { NavLink } from "react-router-dom";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { useState } from "react";
import AppBarShopCenter from "../AppBarShopCenter";

//styled Navlink that hover has no effect

const StyledNavLink = styled(NavLink)`
    text-decoration: none;
    color: inherit;
    &:hover, &:focus, &:active {
        text-decoration: none;
        color: inherit;
        background-color: transparent;
    }
`;


const MenuDrawer = ({ onToggle }) => {

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper'
            }}

        >
            <Box
                display={"flex"}
                justifyContent={"flex-end"}
                padding={2}
            >
                <IconButton
                    onClick={onToggle}
                    disableRipple disableFocusRipple disableTouchRipple>
                    <CloseIcon />
                </IconButton>
            </Box>

            <ListItem
                sx={{
                    justifyContent: "center",
                }}
            >
                <Box
                    display={"flex"}>
                    <AppBarSearch />
                </Box>
            </ListItem>

            <ListItemButton>
                <StyledNavLink
                    style={{
                        display: "block",
                        width: "100%",
                        color: "#00a0c8",
                        textDecoration: "none",
                        textAlign: "center"
                    }}
                    to="/">
                    主頁
                </StyledNavLink>
            </ListItemButton>

            <ListItemButton
                onClick={handleClick}>
                <Box
                    display={"flex"}
                    alignContent={"center"}
                    justifyContent={"center"}
                    textAlign={"center"}
                    width={"100%"}
                    sx={{
                        color: "#00a0c8"
                    }}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="body1">研究社商城</Typography>
                        {
                            open
                                ? <ExpandLess fontSize="small" sx={{ marginLeft: 0 }} />
                                : <ExpandMore fontSize="small" sx={{ marginLeft: 0 }} />
                        }
                    </Box>
                </Box>
            </ListItemButton>

            {open &&
                <ListItem
                    sx={{
                        padding: 0,
                        border: "2px solid #f3f2f2",
                        backgroundColor: "#f3f2f2"
                    }}
                >
                    <AppBarShopCenter
                        position="relative"
                    />
                </ListItem>
            }

            <ListItemButton>
                <StyledNavLink
                    style={{
                        display: "block",
                        width: "100%",
                        color: "#00a0c8",
                        textDecoration: "none",
                        textAlign: "center"
                    }}
                    to="/product">
                    限時推介🐚
                </StyledNavLink>
            </ListItemButton>

            <ListItemButton>
                <StyledNavLink
                    style={{
                        display: "block",
                        width: "100%",
                        color: "#00a0c8",
                        textDecoration: "none",
                        textAlign: "center"
                    }}
                    to="/return-policy">
                    退換貨政策
                </StyledNavLink>
            </ListItemButton>

            <ListItemButton>
                <StyledNavLink
                    style={{
                        display: "block",
                        width: "100%",
                        color: "#00a0c8",
                        textDecoration: "none",
                        textAlign: "center"
                    }}
                    to="/membership">
                    會員制度
                </StyledNavLink>
            </ListItemButton>

            <ListItemButton>
                <StyledNavLink
                    style={{
                        display: "block",
                        width: "100%",
                        color: "#00a0c8",
                        textDecoration: "none",
                        textAlign: "center"
                    }}
                    to="/">
                    聯絡我們
                </StyledNavLink>
            </ListItemButton>

            <Divider />
            <Typography
                variant="body2"
                padding={2}
                textAlign={"center"}
            >
                訂單滿$800免運費! 初次購物可享全單91折優惠!
            </Typography>
        </List>
    )
}

export default MenuDrawer;