//search with debounce
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getProductListApi } from '../api/action/productService';

const useProductSearch = () => {
    const [searchParam, setSearchParam] = useSearchParams();
    const [page, setPage] = useState(parseInt(searchParam.get('page')) || 1);
    const [count, setCount] = useState(0);
    const [productList, setProductList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const abortControllerRef = useRef(null);
    const fetchProducts = useCallback(async (search, size = 1) => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        } else {
            abortControllerRef.current = new AbortController();
        }
        setIsLoading(true);
        await getProductListApi({
            params: {
                page: 1,
                size: size,
                ...search
            },
            signal: abortControllerRef.current.signal
        }).then((response) => {
            setProductList(response.data);
            setCount(Math.ceil(response.data.length / size));
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    const handleSearch = (value) => {
        const searchQuery = Object.fromEntries(searchParam.entries());
        if (value !== searchQuery) { setPage(1); }
        return setSearchParam(value);
    }

    useEffect(() => {
        const searchQuery = Object.fromEntries(searchParam.entries());
        if (searchQuery) {
            fetchProducts({ ...searchQuery, page: page }, 1);
        } else {
            fetchProducts({ page: 1 }, 1);
        }
    }, [searchParam, fetchProducts, page]);



    return { count, productList, isLoading, page, setPage, handleSearch };

}

export default useProductSearch;