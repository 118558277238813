import Stack from '@mui/material/Stack'
import Pagination from '@mui/material/Pagination'
const ProductPagination = ({ page, setPage, count }) => {
    return (
        <Stack spacing={2} sx={{ marginTop: 2 }}>
            <Pagination onChange={(e, v) => { setPage(v) }} page={page} count={count} color="primary" />
        </Stack>
    )
}


export default ProductPagination