import { TableBody, TableRow } from '@mui/material';
import OrderDetailProductItem from './OrderDetailProductItem';


const OrderDetailProductDetail = () => {
    return (
        <TableBody>
            {
                Array.from({ length: 3 }).map((_, index) => (
                    <TableRow key={index}>
                        <OrderDetailProductItem number={index + 1} />
                    </TableRow>
                ))
            }
        </TableBody>
    )
}

export default OrderDetailProductDetail;