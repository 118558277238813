import { Box } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CustomDrawer from './component/drawer/CustomDrawer';
import CartPage from './pages/Cart/cart';
import ConfirmEmail from './pages/ConfirmEmail/confirmEmail';
import Footer from './pages/Footer/footer';
import ForgetPage from './pages/Forget/forget';
import Header from './pages/Header/header';
import HomePage from './pages/Home/home';
import LoginPage from './pages/Login/login';
import MembershipPage from './pages/Membership/membership';
import ProductPage from './pages/Product/product';
import ProfilePage from './pages/Profile/profile';
import RegisterPage from './pages/Register/register';
import ReturnTerm from './pages/ReturmPolicy/return.term';
import { setShopMall } from './redux/slices/header/slicer';
import ProfileEditPage from './pages/ProfileEdit/profileEdit';
import ProfileSkeleton from './component/skeleton/ProfileSkeleton';
import OrderHistoryPage from './pages/OrderHistory/orderHistory';
import OrderDetailPage from './pages/OrderDetail/orderDetail';

const shopMallDummy = [{
  title: "海味乾貨",
  items: [{
    title: "魚乾",
    link: "/product"
  }, {
    title: "海苔",
    link: "/product"
  }]
}]

function App() {
  const isLogin = useSelector((state) => state.auth.isLogin);
  const dispatch = useDispatch();

  const fetchShopMall = useCallback(() => {
    dispatch(setShopMall(shopMallDummy));
  }, [dispatch])

  useEffect(() => {
    fetchShopMall();
  }, [fetchShopMall])
  return (
    <BrowserRouter>
      <Header />
      <Box
        flex={1}
        display={"flex"}
        flexDirection={"column"}
        position={"relative"}
        marginTop={{
          xs: 11,
          md: 23.2,
          xl: 26
        }}
        minHeight={"100%"}
      >
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/product" element={<ProductPage />} />
          <Route path="/return-policy" element={<ReturnTerm />} />
          <Route path="/membership" element={<MembershipPage />} />
          {isLogin ?
            (
              <>
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/profile/edit" element={<ProfileEditPage />} />
                <Route path="/profile/order/history" element={<OrderHistoryPage />} />
                <Route path="/profile/order/detail/:id" element={<OrderDetailPage />} />
              </>
            ) :
            (<>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/forget-password" element={<ForgetPage />} />
            </>
            )
          }
          <Route path="/confirm/email" element={<ConfirmEmail />} />
          <Route path="/test" element={<ProfileSkeleton />} />
        </Routes>
        <CustomDrawer />
      </Box>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
