import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    page: 1,
    limit: 10,
    total: 0,
    data: [],
    keyword: '',
};

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setLimit: (state, action) => {
            state.limit = action.payload;
        },
        setTotal: (state, action) => {
            state.total = action.payload;
        },
        setData: (state, action) => {
            state.data = action.payload;
        },
        setKeyword: (state, action) => {
            state.keyword = action.payload;
        },
        setState: (state, action) => {
            state = action.payload;
        },
    },
});

export const { setPage, setLimit, setTotal, setData, setKeyword, setState } = productSlice.actions;
export default productSlice.reducer;