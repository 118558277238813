
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import { Fragment } from 'react';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    borderRadius: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
}));

const SearchIconWrapper = styled(Button)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0 8px 8px 0',
    '&:hover': {
        backgroundColor: "#00000020",
    },
    color: "black"
}));


const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 2, 1, 2),
        height: '20px',
        // vertical padding + font size from searchIcon
        '&:focus': {
            outlineStyle: 'solid',
            outlineWidth: '4px',
            outlineColor: "#00a0c820",
            borderRadius: '8px 0px 0px 8px',
        },
    },
}));

const ProductSearchBar = ({ handleChange = () => { }, handleClick = () => { } }) => {
    return (
        <Box flex={1} >
            <Fragment>
                <Search flexGrow={1}>
                    <StyledInputBase
                        placeholder="搜索…"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={(e) => { handleChange(e.target.value) }}
                    />
                    <SearchIconWrapper onClick={handleClick}>
                        <SearchIcon />
                    </SearchIconWrapper>
                </Search>
            </Fragment>
        </Box>
    )
};

export default ProductSearchBar;