import { Box, ToggleButton } from "@mui/material";
import StyledToggleButtonGroup from "./ProductViewToggleButton";
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

const ProductToggleSection = ({ view, handleChange }) => {
    return (
        <Box marginLeft={"14px"}>
            <StyledToggleButtonGroup
                orientation="horizontal"
                value={view}
                exclusive
                onChange={handleChange}
            >
                <ToggleButton value="module" aria-label="module">
                    <ViewModuleIcon />
                </ToggleButton>
                <ToggleButton value="list" aria-label="list">
                    <ViewListIcon />
                </ToggleButton>
            </StyledToggleButtonGroup>
        </Box>
    )
}

export default ProductToggleSection;