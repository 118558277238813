import { Box, Container, Grid } from "@mui/material"

const { Fragment } = require("react")
const { default: MembershipCard } = require("./MembershipCard")

const membership = [{
    image: "/membership_1.webp",
    title: "初級社員",
    require: "累計 0 ~ 4000分",
    benefit: "可享有9折優惠"
}, {
    image: "/membership_2.webp",
    title: "高級社員",
    require: "累計 4001 ~ 14000分",
    benefit: "可享有8折優惠"
}, {
    image: "/membership_3.webp",
    title: "資深社員",
    require: "累計 14001 分以上",
    benefit: "可享有7折優惠"
}]

const MembershipSection = () => {
    return (
        <Box sx={{
            bgcolor: "#f0f0f0"
        }}>
            <Container maxWidth="lg" >
                <Grid
                    paddingY={4}
                    container>
                    {membership.map((membership, index) => (
                        <Fragment key={index}>
                            <Grid item xs={12} md={4}>
                                <MembershipCard key={index} membership={membership} />
                            </Grid>
                        </Fragment>
                    ))}
                </Grid>
            </Container>
        </Box>

    )
}

export default MembershipSection