
export default async function isEmailExistApi(email) {
    console.log("isEmailExistApi");

    const response = {
        success: Math.random(4) >= 2,
    }

    // const response = await api.get("/auth/isEmailExist");
    // return response;



    return response.success;
};