import { Box, Paper, Table } from "@mui/material";
import OrderDetailProductDetail from "./OrderDetailProductDetail";
import OrderDetailProductTitle from "./OrderDetailProductTitle";
import OrderDetailProductSummary from "./OrderDetailProductSummary";

const OrderDetailProdcutSection = () => {
    return (
        <Box sx={{ marginY: 2 }}>
            <Paper variant="outlined" sx={{ paddingX: 2 }}>
                <Table sx={{ tableLayout: "auto", maxWidth: "100%" }}>
                    <OrderDetailProductTitle />
                    <OrderDetailProductDetail />
                    <OrderDetailProductSummary
                        totalAmount={3000}
                        discount={100}
                        totalQuantity={3} />
                </Table>

            </Paper>
        </Box>
    )
}

export default OrderDetailProdcutSection;