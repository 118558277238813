import { Box, Skeleton } from '@mui/material';
import { useState, useEffect, useRef } from 'react';

function ImageWithSkeleton({
    imageUrl,
    aspectRatio = 1 / 1,
    maxWidth = "100%",
    minWidth = "auto",
    width = "100%",
    height = "auto",
    needSkeleton = true,
}) {
    const [imageLoaded, setImageLoaded] = useState(!needSkeleton);
    const imageRef = useRef(null);

    useEffect(() => {
        if (!needSkeleton) {
            return;
        }
        // Reset the image loaded state whenever the image URL changes
        setImageLoaded(false);

        if (imageRef.current) {
            // Remove the previous image event listener
            imageRef.current.removeEventListener('load', handleImageLoad);
        }

        // Add a new image event listener
        const img = new Image();
        img.src = imageUrl;
        img.addEventListener('load', handleImageLoad);
        imageRef.current = img;

        return () => {
            // Clean up the event listener when the component is unmounted
            img.removeEventListener('load', handleImageLoad);
        };
    }, [imageUrl, needSkeleton]);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    return (
        <Box
            sx={{
                width: width,
                maxWidth: maxWidth,
                height: height,
                aspectRatio: aspectRatio,
                minWidth: minWidth,
            }}
        >
            {!imageLoaded ?
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="100%"
                /> :
                <img
                    src={imageUrl}
                    alt='product'
                    onLoad={() => setImageLoaded(true)}
                    style={{
                        display: imageLoaded ? 'block' : 'none',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
            }
        </Box>
    );

}

export default ImageWithSkeleton;