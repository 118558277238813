import { Box, Grid, Typography } from "@mui/material"
import ImageWithSkeleton from "../../../component/image/ImageSkeleton"
import { Fragment } from "react"

const HomeSnackbarCard = ({ key, name, imageUrl, price }) => {
    return (
        <Fragment key={key}>
            <Box
                padding={2}
            >
                <Grid container spacing={2}>
                    <Grid item >
                        <ImageWithSkeleton
                            maxWidth={"50px"}
                            imageUrl={imageUrl}
                            aspectRatio={1 / 1} />
                    </Grid>
                    <Grid
                        item xs={8}
                        alignItems={"center"}>
                        <Typography
                            variant="body2"
                        >
                            {name}
                        </Typography>
                        <Typography
                            variant="body2"
                        >
                            ${price}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    )
}

export default HomeSnackbarCard