import { _get } from "../apiClient";

// export const getProductListApi = async (params = {}) => _get("/product", params);
const dumpData = [{
    id: 1,
    name: "product name",
    price: 100,
    imageUrl: "/square.jpg"
}, {
    id: 2,
    name: "product name 2",
    price: 200,
    imageUrl: "/square.jpg"
}, {
    id: 3,
    name: "南非乾鮑10-15頭 (1斤裝)",
    price: 1000,
    imageUrl: "/square.jpg",
    description: "藍旗吞拿魚是來自大西洋的最高級吞拿品種\n以其上乘的肉質質地和獨特的鮮美口感而聞名。\n這種野生吞拿魚生長在清澈乾淨的海域, 飽滿肥美的肌肉呈現優雅的紅褐色, 肉質細膩軟滑, 富含豐富的天然油脂。"
}, {
    id: 4,
    name: "宮崎產 太陽之子 赤秀級 糖度15保證 2個裝 (共1.2kg)",
    price: 1000,
    imageUrl: "/square.jpg",
    description: "糖度15保證 2個裝 (共1.2kg)"
}, {
    id: 5,
    name: "宮崎產 太陽之子 赤秀級 糖度15保證 2個裝 (共1.2kg)",
    price: 1000,
    imageUrl: "/square.jpg",
    description: "糖度15保證 2個裝 (共1.2kg)"
}]

export const getProductListApi = async ({ params, signal }) => {
    await new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(dumpData);
        }, 500);
    });
    const shuffleSlice = 1 + Math.random() * (dumpData.length - 1);
    if (params.category)
        return { data: dumpData.slice(0, shuffleSlice) };
    if (params.keyword)
        return { data: dumpData.slice(0, shuffleSlice) };
    else
        return { data: [] };
}

export const getProductDetailApi = async (params = {}) => _get(`/product`, params);

export const getRelatedProductApi = async (params = {}) => _get(`/product/related`, params);

export const getTopProductApi = async (params = {}) => _get(`/product/top`, params);

export const getNewProductApi = async (params = {}) => _get(`/product/new`, params);

