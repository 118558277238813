import { Container, Typography } from "@mui/material";
import OrderListSection from "./component/OrderListSection";

const dumpyData = [{
    orderDate: "2021-09-01",
    orderNumber: "123456",
    status: "已完成",
    total: 1000,
    image: ["https://via.placeholder.com/150"]
}]

const OrderHistoryPage = () => {
    return (
        <Container maxWidth="lg" sx={{ marginY: 4 }}>
            <Typography variant="h4" sx={{ marginBottom: 2 }}>
                訂單紀錄
            </Typography>
            <OrderListSection orders={dumpyData} />
        </Container>
    )
}

export default OrderHistoryPage;