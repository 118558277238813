import { Grid, Stack, Typography } from "@mui/material"
import ProfileDetail from "./ProfileDetail"
import ProfileSectionButton from "./ProfileSectionButton"

const ProfileSection = ({ handleLogout, user }) => {
    return (
        <>
            <Typography
                marginBottom={2}
                variant="h4">
                我的帳戶
            </Typography>
            <Grid
                direction={{
                    xs: "column-reverse",
                    sm: "row"
                }}
                container
                spacing={2}>
                <Grid
                    xs={12}
                    sm={6}
                    item
                >
                    <Stack spacing={2}>
                        <ProfileSectionButton
                            text="更改個人資料"
                            url="/profile/edit"
                        />
                        <ProfileSectionButton
                            text="我的訂單"
                            url="/profile/order/history"
                        />
                        <ProfileSectionButton
                            text="我的積分"
                            url="/profile/points"
                        />
                        <Typography
                            sx={{ cursor: 'pointer' }}
                            textAlign="center"
                            variant="body1"
                            onClick={handleLogout.handleLogout}
                        >
                            登出
                        </Typography>
                    </Stack>
                </Grid>
                <Grid
                    xs={12}
                    sm={6}
                    item
                >
                    <ProfileDetail
                        name={user.lastName + " " + user.firstName}
                        title="初級社員"
                        email={user.email}
                        address={user.address}
                        phone={user.phoneNumber}
                    />
                </Grid>
            </Grid>
        </>
    )
}


export default ProfileSection