import { Container } from "@mui/material";
import ProfileEditForm from "../../component/form/profileEdit/ProfileEditForm";

const ProfileEditPage = () => {
    return (
        <Container maxWidth="lg" sx={{ marginY: 6 }}>
            <ProfileEditForm />
        </Container>
    )
}

export default ProfileEditPage;