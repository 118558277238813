import { Box, Divider, Grid, Stack, Typography } from "@mui/material"
import { NavLink } from "react-router-dom"

const AppBarShopCenterItem = ({ title, items }) => {
    return (
        <Grid
            item
            xs={12}
            md={3}
            paddingX={1}
            marginY={2}
        >
            <Stack>
                <Typography
                    color="black"
                    variant="h6"
                >
                    {title}
                </Typography>
                <Divider
                    sx={{
                        marginY: 1,
                        height: "2px"
                    }}
                    color="#00a0c8"
                />
                {items.map((item, index) => (
                    <Box
                        marginY={1}
                        key={index}>
                        <NavLink
                            style={{
                                display: "block",
                                color: "#00a0c8",
                                textDecoration: "none",
                            }}
                            to={{
                                pathname: "/product",
                                search: `?field=${item.title}`
                            }}
                        >
                            {item.title}
                        </NavLink>
                    </Box>
                ))}
            </Stack>
        </Grid>
    )
}

export default AppBarShopCenterItem