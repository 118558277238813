import { Box, Container } from "@mui/material";
import RegisterForm from "../../component/form/register/RegisterForm";

const RegisterPage = () => {
    return (
        <Container maxWidth="xs" sx={{ background: "white" }}>
            <Box sx={{ paddingY: 4 }}>
                <RegisterForm />
            </Box>
        </Container>
    )
}

export default RegisterPage;