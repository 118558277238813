import { Stack, Typography } from "@mui/material"

const AppBarAnnouncement = () => {
    return (
        <Stack
            display={{ xs: 'none', md: 'block' }}
            textAlign="center"
            color="white"
            backgroundColor="#00A0C8"
        >
            <Typography variant="body2" padding={1}>
                訂單滿$800免運費! 初次購物可享全單91折優惠!
            </Typography>
        </Stack>
    )
}

export default AppBarAnnouncement