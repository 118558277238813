import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isFetched: false,
    profileImg: null,
    title: "初級社員",
    memberId: 0,
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    gender: "M",
    point: 0,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setMemberId: (state, action) => {
            state.memberId = action.payload;
        },
        setProfileImg: (state, action) => {
            state.profileImg = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setPhoneNumber: (state, action) => {
            state.phoneNumber = action.payload;
        },
        setFirstName: (state, action) => {
            state.firstName = action.payload;
        },
        setLastName: (state, action) => {
            state.lastName = action.payload;
        },
        setAddress: (state, action) => {
            state.address = action.payload;
        },
        setPoint: (state, action) => {
            state.point = action.payload;
        },
        setProfileState: (state, action) => {
            state.isFetched = true;
            state.email = action.payload.email;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.phoneNumber = action.payload.phoneNumber;
            state.address = action.payload.address;
            state.gender = action.payload.gender;
            state.memberId = action.payload.memberId;
        },
        clearProfile: (state) => {
            state = initialState
        }
    },
});

export const { setProfileImg, setUsername, setEmail, setPhoneNumber: setPhone, setFirstName, setLastName, setAddress, setProfileState, setPoint } = userSlice.actions;
export default userSlice.reducer;