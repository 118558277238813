import { Box, Container } from "@mui/material"
import useLogout from "../../hooks/useLogout"
import useFetchUserProfile from "../../hooks/useUserProfile"
import ProfileSection from "./component/ProfileSection"
import ProfileSkeleton from "../../component/skeleton/ProfileSkeleton"

const ProfilePage = () => {
    const handleLogout = useLogout();
    const { user } = useFetchUserProfile();
    return (
        <Container maxWidth="lg" sx={{ marginY: 4 }}>
            <Box>
                {
                    user.isFetched
                        ? <ProfileSection handleLogout={handleLogout} user={user} />
                        : <ProfileSkeleton />
                }
            </Box>
        </Container >
    )
}


export default ProfilePage