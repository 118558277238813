import { Card, Paper, Stack, Typography } from "@mui/material"
import { Fragment } from "react"
import { NavLink } from "react-router-dom"
import CustomHoverSlideBox from "../../../component/box/CustomHoverSlideBox"
import ImageWithSkeleton from "../../../component/image/ImageSkeleton"
import CurrencyText from "../../../component/text/CurrencyText"

const OrderListItem = ({ orderDate, orderNumber, status, total, image = [] }) => {
    return (
        <Paper
            variant="outlined"
            sx={{
                padding: 2,
                position: "relative",
            }}
        >
            {/* absolute to the right corner */}
            <NavLink
                to={`/profile/order/detail/${orderNumber}`}
                style={{
                    textDecoration: "none",
                    color: "inherit",
                    position: "absolute",
                    right: 0,
                    top: 0
                }}
            >
                <Card
                    variant="outlined"
                    sx={{
                        padding: 1,
                        margin: 1,
                        boxShadow: 3,
                        backgroundColor: "primary.light",
                        color: "white",
                        "&:hover": {
                            backgroundColor: "primary.dark"
                        }
                    }}
                >
                    <Typography variant="body2">
                        訂單詳情
                    </Typography>
                </Card>
            </NavLink>
            <Typography>
                訂單日期: {orderDate}
            </Typography>
            <Typography>訂單編號: <span style={{ color: "#00A0C8", fontWeight: "bold" }}>{orderNumber}</span></Typography>
            <Typography>
                訂單狀態: {status}
            </Typography>
            <CurrencyText amount={total} preText={"總金額: "} />
            <Typography>
                商品圖片:
            </Typography>
            <CustomHoverSlideBox sx={{ display: "flex", overflowX: 'scroll' }}>
                <Stack direction="row" spacing={2}>
                    {
                        // repeat 10 times
                        Array.from({ length: 10 }).map((_, index) => (
                            <Fragment key={index}>
                                <ImageWithSkeleton
                                    imageUrl={image[0]}
                                    aspectRatio={1 / 1}
                                    width={100}
                                    height={100}
                                />
                            </Fragment>
                        ))
                    }
                </Stack>
            </CustomHoverSlideBox>
        </Paper >
    )
}

export default OrderListItem