import { Box, Grid, List, ListItem, Skeleton, Stack } from "@mui/material";

const ProfileSkeleton = () => {
    return (
        <>
            <Skeleton variant="rectangular" width={200} height={50} sx={{ marginY: 2 }} />
            <Grid
                direction={{
                    xs: "column-reverse",
                    sm: "row"
                }}
                container
                spacing={2}>
                <Grid
                    xs={12}
                    sm={6}
                    item
                >
                    <Stack spacing={2}>
                        <Skeleton variant="rectangular" height={50} />
                        <Skeleton variant="rectangular" height={50} />
                        <Skeleton variant="rectangular" height={50} />
                        <Skeleton variant="rectangular" width={50} height={30} sx={{ justifySelf: "center" }} />
                    </Stack>
                </Grid>
                <Grid
                    xs={12}
                    sm={6}
                    item
                >
                    <List
                        disablePadding
                        sx={{
                            borderRadius: 2,
                            border: 1,
                            borderColor: 'divider',
                        }}
                    >
                        <ListItem sx={{ display: "flex" }} alignItems={"center"}>
                            <Skeleton variant="rectangular" width={52} height={52} sx={{ margin: 1 }} />
                            <Box sx={{ flex: 1 }}>
                                <Skeleton variant="rectangular" sx={{ flex: 1 }} height={20} />
                                <Skeleton variant="rectangular" sx={{ flex: 1 }} height={20} />
                            </Box>
                        </ListItem>

                        <ListItem alignItems="center">
                            <Skeleton variant="rectangular" width={12} height={12} sx={{ margin: 1 }} />
                            <Skeleton variant="rectangular" sx={{ flex: 1 }} height={12} />
                        </ListItem>

                        <ListItem alignItems="center">
                            <Skeleton variant="rectangular" width={12} height={12} sx={{ margin: 1 }} />
                            <Skeleton variant="rectangular" sx={{ flex: 1 }} height={12} />
                        </ListItem>

                        <ListItem alignItems="center">
                            <Skeleton variant="rectangular" width={12} height={12} sx={{ margin: 1 }} />
                            <Skeleton variant="rectangular" sx={{ flex: 1 }} height={12} />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </>
    )
}


export default ProfileSkeleton;