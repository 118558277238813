import PersonIcon from '@mui/icons-material/Person';
import { Box, ListItemIcon, Menu, MenuItem, Typography, styled } from "@mui/material";
import React, { Fragment } from "react";
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import useLogout from '../../../hooks/useLogout';
import Logout from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const StyledBox = styled(Box)(({ theme }) => ({
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: "#e5e5e5",
    borderRadius: '999px',
    width: '36px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    cursor: "pointer",
    justifyContent: 'center',
}));


const ProfileBlock = ({ isLogin, username = "" }) => {
    return (
        <Fragment>
            <Box
                display={'flex'}
                marginX={{
                    xs: 1,
                    md: 0
                }}
                padding={{
                    md: "4px 8px",
                    xs: "0px"
                }}
                sx={{
                    cursor: "pointer",
                    "&:hover": {
                        backgroundColor: "#f5f5f5",
                        borderRadius: {
                            xs: '999px',
                            md: '12px'
                        },
                    }
                }}
                alignItems="center"
            >
                <StyledBox
                    sx={{
                        backgroundColor: {
                            sx: "#ffffff",
                            md: "#f6f6f6"
                        },
                    }}
                    color="secondary">
                    <PersonIcon
                        fontSize="10px" />
                </StyledBox>
                <Typography
                    marginLeft={1}
                    noWrap
                    fontSize={12}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                    fontWeight={600}
                    color="textSecondary">
                    {isLogin ? username : "登入/註冊"}
                </Typography>
            </Box >
        </Fragment>
    )
}

const AppBarProfile = () => {
    const navigage = useNavigate()
    const isLogin = useSelector(state => state.auth.isLogin);
    const username = useSelector(state => state.user.username);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const handleClick = () => {
        setOpen(true);
    }
    const handleLogout = useLogout();
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <Fragment>
            {
                isLogin
                    ?
                    <Fragment>
                        <Box
                            ref={anchorRef}
                            onClick={handleClick}
                        >
                            <ProfileBlock isLogin={isLogin} username={username} />
                        </Box>
                        <Menu
                            width={"100%"}
                            id="basic-menu"
                            anchorEl={anchorRef.current}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'lock-button',
                                role: 'listbox',
                            }}
                        >
                            <MenuItem
                                onClick={(event) => {
                                    handleClose(event);
                                    navigage("/profile")
                                }}
                            >
                                <ListItemIcon>
                                    <AccountCircleIcon fontSize="small" />
                                </ListItemIcon>
                                我的帳戶
                            </MenuItem>
                            <MenuItem onClick={(event) => {
                                handleClose(event);
                                handleLogout.handleLogout()
                            }}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                登出
                            </MenuItem>
                        </Menu >
                    </Fragment>
                    :
                    <NavLink
                        to={"/login"}
                        style={{
                            textDecoration: "none",
                            color: "black"
                        }}
                    >
                        <ProfileBlock isLogin={isLogin} username={username} />
                    </NavLink>
            }

        </Fragment >
    )
}

export default AppBarProfile;