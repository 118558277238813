import axios from 'axios';
import refreshTokenApi from './action/refreshTokenAPI';
import { store } from '../redux/store';
import { logout } from '../redux/slices/auth/slicer';
import { setProfileState } from '../redux/slices/user/slicer';
const refreshExpiredTokenClosure = () => {
    let isCalled = false;
    let runningPromise = undefined;
    return () => {
        if (isCalled) {
            return runningPromise;
        } else {
            isCalled = true;
            runningPromise = refreshTokenApi();
            return runningPromise;
        }
    };
};

const refreshExpiredToken = refreshExpiredTokenClosure();

const apiClient = axios.create({
    baseURL: 'http://192.168.1.249:7074',
    headers: {
        'Content-Type': 'application/json'
    },
});


//for request interceptor
apiClient.interceptors.request.use(
    (config) => {
        // Get the access token from your token management logic
        const accessToken = sessionStorage.getItem('accessToken');

        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject({
            success: false,
            data: error
        });
    }
);

//for response interceptor
apiClient.interceptors.response.use(
    (response) => {
        return {
            success: true,
            data: response
        };
    },
    async (error) => {
        const originalRequest = error.config;
        // Check if the error is a 401 Unauthorized error
        if (/*error.response.status === 401 && */!originalRequest._retry) {
            // Call your API to refresh the access token
            const data = await refreshExpiredToken(error.config.url);
            if (!data) {
                store.dispatch(logout());
                store.dispatch(setProfileState({}));
                sessionStorage.clear();
                return window.location.href = '/login';
            }
            sessionStorage.setItem('accessToken', data.accessToken);
            originalRequest._retry = true;

            // Retry the original request with the new access token
            return apiClient(originalRequest);
        }

        return Promise.reject({
            success: false,
            data: error
        });
    }
);


const _get = (url, config = {}) => {
    return apiClient.get(url, config);
};

const _delete = (url, config = {}) => {
    return apiClient.delete(url, config);
};

const _put = (url, data = {}, config = {}) => {
    return apiClient.put(url, data, config);
};

const _post = (url, data = {}, config = {}) => {
    return apiClient.post(url, data, config);
};


export { _get, _delete, _put, _post };
export default apiClient;