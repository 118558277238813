import { Container, Grid, Typography } from "@mui/material"
import HomeProductCard from "./HomeProductCard"
import { Fragment } from "react"

const dumpData = [{
    id: 1,
    name: "product name",
    price: 100,
    imageUrl: "/square.jpg"
}, {
    id: 2,
    name: "product name 2",
    price: 200,
    imageUrl: "/square.jpg"
}, {
    id: 3,
    name: "南非乾鮑10-15頭 (1斤裝)",
    price: 1000,
    imageUrl: "/square.jpg",
    description: "藍旗吞拿魚是來自大西洋的最高級吞拿品種,以其上乘的肉質質地和獨特的鮮美口感而聞名。\n這種野生吞拿魚生長在清澈乾淨的海域, 飽滿肥美的肌肉呈現優雅的紅褐色, 肉質細膩軟滑, 富含豐富的天然油脂。"
}, {
    id: 4,
    name: "宮崎產 太陽之子 赤秀級 糖度15保證 2個裝 (共1.2kg)",
    price: 1000,
    imageUrl: "/square.jpg",
    description: "糖度15保證 2個裝 (共1.2kg)"
}]

const HomeProductSection = () => {
    return (
        <Container maxWidth="lg">
            <Typography
                variant="h3"
                textAlign={"center"}
                padding={2}
                fontWeight={"bold"}
            >
                熱門商品
            </Typography>
            <Grid
                container
                spacing={2}
            >
                {
                    dumpData.map((product) => (
                        <Fragment key={product.id}>
                            <HomeProductCard
                                product={product}
                            />
                        </Fragment>
                    ))
                }
            </Grid>
        </Container>
    )
}


export default HomeProductSection;
