import { AppBar, Box, Container, Toolbar } from "@mui/material";
import AppBarSearch from "./AppBarSearch";
import AppLogo from "./AppBarLogo";
import AppBarCart from "./AppBarCart";
import AppBarProfile from "./AppBarProfile";
import AppBarBurger from "./AppBarBurger";

const AppBarTopMenu = ({ isLogin }) => {
    return (
        <AppBar
            sx={{
                backgroundColor: 'white',
                color: 'black',
                paddingX: { xs: 0, md: 2 },
                paddingY: 1,
                borderBottom: { xs: 0, md: 1 },
                borderColor: { xs: 'transparent', md: '#e0e0e0' },
                position: { xs: 'sticky', md: 'static' },
                boxShadow: { xs: '0px 0px 5px 0px rgba(0,0,0,0.5)', md: 'none' },
            }}
        >
            <Container maxWidth="lg">
                <Toolbar disableGutters>
                    <AppLogo />
                    <Box
                        padding={{
                            md: "8px 16px",
                            xs: "0"
                        }}
                        display={{ xs: 'none', md: 'flex' }}>
                        <AppBarSearch />
                    </Box>
                    <AppBarCart />
                    <AppBarProfile isLogin={isLogin} />
                    <AppBarBurger />
                </Toolbar>
            </Container>
        </AppBar>
    )
}


export default AppBarTopMenu;