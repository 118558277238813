import { Container, Typography } from "@mui/material";
import OrderDetailSection from "./component/OrderDetailSection";
import OrderDetailProdcutSection from "./component/OrderDetailProductSection";
import OrderDetailPaymentSection from "./component/OrderDetailPaymentSection";

const OrderDetailPage = () => {
    return (
        <Container maxWidth="lg" sx={{ marginY: 4 }}>
            <Typography variant="h4" sx={{ marginBottom: 2 }}>
                訂單明細
            </Typography>
            <OrderDetailSection />
            <OrderDetailProdcutSection />
            <OrderDetailPaymentSection />
        </Container>
    )
}


export default OrderDetailPage;