import { Card, CardActionArea, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

const ProfileSectionButton = ({ text, url }) => {
    return (
        <Card
            elevation={4}
            sx={{
                borderRadius: 2,
            }}
        >
            <CardActionArea
                sx={{
                    padding: 2,
                    borderRadius: 2,
                }}
            >
                <NavLink
                    to={url}
                    style={{
                        textDecoration: 'none',
                        color: 'black',
                        display: 'block',
                    }}
                >
                    <Typography variant="h6">
                        {text}
                    </Typography>
                </NavLink>
            </CardActionArea>
        </Card>

    )
}

export default ProfileSectionButton;