import { Fragment } from "react"
import MembershipBoard from "./component/MembershipBoard"
import MembershipSection from "./component/MembershipSection"



const MembershipPage = () => {
    return (
        <Fragment>
            <MembershipBoard />
            <MembershipSection />
        </Fragment>
    )
}

export default MembershipPage