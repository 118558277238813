import { Box, Divider, Grid, Skeleton, Stack } from "@mui/material";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import AppBarShopCenterItem from "./AppBarShopCenterItem";


const SkeletonShopItem = () => {
    return (
        <Fragment>
            <Grid
                paddingX={1}
                marginTop={1}
                marginBottom={2}
                item xs={12} md={3}>
                <Stack spacing={2}>
                    <Skeleton
                        variant="text"
                        height={40}
                    />
                    <Divider />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Stack>
            </Grid>
            <Grid
                paddingX={1}
                marginTop={1}
                marginBottom={2}
                item xs={12} md={3}>
                <Stack spacing={2}>
                    <Skeleton
                        variant="text"
                        height={40}
                    />
                    <Divider />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Stack>
            </Grid>
            <Grid
                paddingX={1}
                marginTop={1}
                marginBottom={2}
                item xs={12} md={3}>
                <Stack spacing={2}>
                    <Skeleton
                        variant="text"
                        height={40}
                    />
                    <Divider />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Stack>
            </Grid>
            <Grid
                paddingX={1}
                marginTop={1}
                marginBottom={2}
                item xs={12} md={3}>
                <Stack spacing={2}>
                    <Skeleton
                        variant="text"
                        height={40}
                    />
                    <Divider />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Stack>
            </Grid>
        </Fragment>
    )
}

const AppBarShopCenter = () => {
    const shopMall = useSelector(state => state.header.shopMall);
    return (
        <Box
            left={0}
            width={"100%"}
            backgroundColor={"#fff"}
            boxShadow={"none"}
        >
            <Grid
                paddingX={1}
                container>
                {!shopMall.isLoaded && <SkeletonShopItem />}
                {shopMall.malls.map((mall, index) => (
                    <Fragment key={index}>
                        <AppBarShopCenterItem
                            title={mall.title}
                            items={mall.items}
                        />
                    </Fragment>
                ))}
            </Grid>
        </Box>
    )
}

export default AppBarShopCenter