import { Pagination, Stack } from "@mui/material";
import OrderListItem from "./OrderListItem";

const OrderListSection = ({ orders = [], count = 0 }) => {
    return (
        <Stack spacing={2}>
            {
                orders.map((order, index) => (
                    <OrderListItem
                        key={index}
                        {...order}
                    />
                ))
            }
            <Pagination count={count} color="primary" />
        </Stack>
    )
}


export default OrderListSection;