import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    title: null,
    isToggle: false,
    section: null,
};

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setTitle: (state, action) => {
            state.title = action.payload;
        },
        setToggle: (state, action) => {
            state.isToggle = action.payload;
        },
        setSection: (state, action) => {
            state.section = action.payload;
        }
    },
});

export const { setTitle, setToggle, setSection } = ordersSlice.actions;
export default ordersSlice.reducer;