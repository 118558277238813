
const getProfileApi = async () => {
    // return await _get("/profile");
    await new Promise(
        (resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, 1000);
        }
    );

    return {
        success: true,
        data: {
            memberId: 0,
            profileImg: null,
            email: "test@gmail.com",
            firstName: "First",
            lastName: "Last",
            phone: "12345678",
            address: "test 1",
            gender: "M",
            point: 0,
        }
    };
}
export default getProfileApi;