import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Divider, Stack, Typography, useTheme } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import CurrencyText from "../../../component/text/CurrencyText";

const CartTotalBoard = ({ cart }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    return (
        <Stack
            sx={{
                [theme.breakpoints.up('md')]: {
                    padding: 2,
                    borderRadius: "8px",
                    border: "1px solid #e0e0e0",
                }
            }}
        >
            < Box
                margin={2}
                display={"flex"}
                justifyContent={"space-between"}>
                <Typography>
                    小計:
                </Typography>
                <CurrencyText
                    amount={cart.total}
                />
            </Box>
            <Box
                margin={2}
                display={"flex"}
                justifyContent={"space-between"}>
                <Typography>
                    折扣:
                </Typography>
                <CurrencyText
                    amount={cart.discount}
                />
            </Box>
            <Divider />

            <Box
                margin={2}
                display={"flex"}
                justifyContent={"space-between"}

            >
                <Typography fontWeight={"bold"}>
                    總金額:
                </Typography>
                <CurrencyText
                    fontWeight={"bold"}
                    amount={cart.total - cart.discount}
                />
            </Box>
            <Stack margin={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        navigate("/cart?page=1")
                    }}
                >
                    結帳 <ArrowForwardIosIcon fontSize="10" />
                </Button>
            </Stack>
            <Divider>或</Divider>
            <Stack
                textAlign={"center"}
                margin={2}
            >
                <NavLink
                    to="/"
                    style={{
                        textDecoration: "none",
                        color: "#007390",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}

                >

                    <ArrowBackIosIcon fontSize="10" />
                    <Typography>
                        繼續購物
                    </Typography>
                </NavLink>
            </Stack>
        </Stack>
    )
};


export default CartTotalBoard;