import { Box, Button, Divider, Stack, styled, Typography } from "@mui/material"
import ImageWithSkeleton from "../../../component/image/ImageSkeleton"
import QuantityInput from "./CartNumberInput"
import { removeFromCart, updateFromCart } from "../../../redux/slices/cart/slicer"
import { useDispatch } from "react-redux"
import DeleteIcon from '@mui/icons-material/Delete';
import { grey } from '@mui/material/colors';
import CurrencyText from "../../../component/text/CurrencyText"
import { Fragment } from "react"
import StyledNavLink from "../../../component/button/StyledNavLink"

const ColorButton = styled(Button)(() => ({
    color: "#fff",
    backgroundColor: grey[200],
    '&:hover': {
        backgroundColor: grey[500],
    },
}));


const CartProductCard = ({ product }) => {
    const dispatch = useDispatch();
    const handleQuantityChange = (e, newValue) => {
        dispatch(updateFromCart({
            id: product.id,
            quantity: newValue
        }))
    }
    const handleDelete = () => {
        dispatch(removeFromCart({
            id: product.id
        }))
    }
    return (
        <Fragment>
            <Stack
                padding={"12px 0px"}
            >
                <Box display={"flex"} >
                    <Stack
                        sx={{
                            width: "65px",
                            display: "block",
                        }}
                    >
                        <ImageWithSkeleton
                            imageUrl={product.imageUrl}
                            width="65px"
                            maxWidth="65px"
                            aspectRatio={1 / 1}
                        />
                    </Stack>
                    <Box
                        display={"flex"}
                        alignItems={"flex-start"}
                        flex={1}>
                        <Stack padding={"0px 12px"}>
                            <Box>
                                <StyledNavLink
                                    to={`/product?id=${product.id}`}>
                                    <Typography
                                        fontWeight={"bold"}
                                    >
                                        {product.name}
                                    </Typography>
                                </StyledNavLink>
                            </Box>
                            <Box
                                display={{
                                    xs: "none",
                                    sm: "block"
                                }}>
                                <Typography
                                    fontWeight={400}
                                    margin={"8px 0"}
                                    color={"grey"}
                                    variant="body2">
                                    {product.description}
                                </Typography>
                            </Box>
                            <Box>
                                <ColorButton
                                    onClick={handleDelete}
                                >
                                    <DeleteIcon fontSize="small" />
                                </ColorButton>
                            </Box>

                        </Stack>
                    </Box>
                    <Stack justifyContent={"start"}>
                        <QuantityInput
                            value={product.quantity}
                            onChange={handleQuantityChange}
                        />
                        <Box marginTop={1}>
                            <CurrencyText
                                textAlign={"right"}
                                amount={parseFloat((product.price * product.quantity).toString()).toFixed(2)}
                            />


                        </Box>
                    </Stack>
                </Box >
            </Stack>
            <Divider />
        </Fragment >
    )
}

export default CartProductCard