import { Box, Container } from '@mui/material';

const VideoSkeleton = ({ videoId }) => {
    return (
        <Container maxWidth="md">
            <Box
                sx={{
                    width: "100%",
                    aspectRatio: 16 / 9,
                }}
            >
                <iframe
                    width="100%"
                    height={"100%"}
                    src={`https://www.youtube.com/embed/${videoId}`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    loading="lazy"
                ></iframe>
            </Box>
        </Container>
    );
};

export default VideoSkeleton;