import { useEffect } from "react";

const useTitleHooks = (title) => {
    useEffect(() => {
        const baseTitle = "香港街市魚類海鮮研究社";
        document.title = `${baseTitle} - ${title}`;
        return () => {
            document.title = baseTitle;
        };
    }, [title]);
};

export default useTitleHooks;