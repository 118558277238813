import { Box, Paper, Typography } from "@mui/material";

const OrderDetailSection = () => {
    return (
        <Box sx={{ marginY: 2 }}>
            <Paper
                variant="outlined"
                sx={{ padding: 2 }}>
                <Typography variant="h6" color={"#00A0C8"} fontWeight={"bold"} >
                    訂單編號: 123456
                </Typography>
                <Typography variant="body1">
                    訂單日期: 2021-09-21
                </Typography>
                <Typography variant="body1">
                    訂單狀態: 已完成
                </Typography>
            </Paper>
        </Box>
    )
}

export default OrderDetailSection;