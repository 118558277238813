import axios from "axios";

//refresh token is store in httpsonly cookies so we can't access it from javascript, and we refresh token by sending a request to the server
export default async function refreshTokenApi() {
    const { data } = await axios.post(
        '/refresh-token',
        {
            withCredentials: true
        }
    ).then(response => {
        return response;
    }).catch(error => {
        return { data: null };
    });
    return data;

}