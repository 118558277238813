import { Box, styled, Tab, Tabs } from "@mui/material";
import { useCallback, useState } from "react";


const CustomStyledTabs = styled(Tabs)({
    border: 0,
    borderRadius: '8px',
    width: "100%",
    '& .MuiTabs-indicator': {
        display: 'none',
    },

});


const CustomStyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        margin: theme.spacing(1),
        color: "black",
        borderRadius: '8px',
        backgroundColor: "#f3f2f2",
        '&.Mui-selected': {
            backgroundColor: '#dbeaee',
            border: '1px solid #e8e8e8',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);

const category = [
    {
        id: 1,
        name: "全部"
    },
    {
        id: 2,
        name: "水果"
    },
    {
        id: 3,
        name: "蔬菜"
    },
    {
        id: 4,
        name: "肉類"
    },
    {
        id: 5,
        name: "海鮮"
    },
    {
        id: 6,
        name: "乳製品"
    },
    {
        id: 7,
        name: "零食"
    },
    {
        id: 8,
        name: "飲料"
    },
    {
        id: 9,
        name: "調味料"
    },
    {
        id: 10,
        name: "其他"
    }
]


const ProductCategorySection = ({ search }) => {
    const [value, setValue] = useState(0);
    const handleChange = useCallback((event, newValue) => {
        setValue(newValue);
        search(category[newValue].name);
    }, [search]);
    return (
        <Box marginY={2}>
            <CustomStyledTabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
                visibleScrollbar={true}
                aria-label="scrollable auto tabs example"
            >
                {
                    category.map((item) => (
                        <CustomStyledTab
                            key={item.id}
                            label={item.name}
                        />
                    ))
                }
            </CustomStyledTabs>
        </Box>
    )
}

export default ProductCategorySection