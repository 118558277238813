
import { Box } from "@mui/material";
import { useCallback, useState } from 'react';
import ProductSearchBar from "./ProductSearchBar";
import ProductToggleSection from "./ProductToggleSection";
const ProductSearchSection = ({ view, setView, search }) => {
    const handleChange = (event, nextView) => {
        if (nextView === null) return;
        setView(nextView);
    };
    const [keyword, setKeyword] = useState("");
    const handleFetch = useCallback(() => {
        if (keyword === "") return;
        search(keyword);
    }, [search, keyword]);

    return (
        <Box marginY={2}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <ProductSearchBar handleClick={handleFetch} handleChange={setKeyword} />
                <ProductToggleSection view={view} handleChange={handleChange} />
            </Box>
        </Box>
    )
}

export default ProductSearchSection;