import { Container, List, ListItem, Paper, Stack, Typography } from "@mui/material";
import ImageWithSkeleton from "../../../component/image/ImageSkeleton";

const MembershipCard = ({ membership }) => {
    return (
        <Container maxWidth="lg">
            <Paper
                elevation={3}
                sx={{
                    my: 2,
                    textAlign: "center",
                }}
            >
                <Stack>
                    <ImageWithSkeleton
                        imageUrl={membership.image}
                        alt={membership.title}
                        aspectRatio={16 / 9}
                    />
                </Stack>
                <List>
                    <ListItem>
                        <Typography variant="h4">{membership.title}</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body2">{membership.require}</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">{membership.benefit}</Typography>
                    </ListItem>
                </List>
            </Paper>
        </Container>
    )
}


export default MembershipCard;