import { Box, Paper, Modal, Typography } from '@mui/material';
import QRCode from 'react-qr-code';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid #fefefe',
    borderRadius: 4,
    boxShadow: 24,
};

const ProfileQrCode = ({ value, open, handleClose }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography
                    textAlign={"center"}
                    margin={2}
                    id="modal-modal-title" variant="h6" component="h2">
                    Member QR Code
                </Typography>
                <Paper
                    elevation={3}
                    sx={{
                        margin: 2,
                        padding: 2,
                        borderRadius: 0,
                    }}
                >
                    <QRCode
                        value={value} />
                </Paper>
            </Box>
        </Modal>
    )
}

export default ProfileQrCode;