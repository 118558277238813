import { Box, Container } from "@mui/material";
import React from "react";
import ForgetForm from "../../component/form/forget/ForgetForm";

const ForgetPage = () => {
    // const [step, setStep] = useState(1);
    const handleSubmit = (e) => {
    }

    return (
        <Container maxWidth="xs" sx={{ background: "white" }}>
            <Box sx={{ paddingY: 4 }}>
                <ForgetForm handleFormSubmit={handleSubmit} />
            </Box>
        </Container>
    )
}

export default ForgetPage;