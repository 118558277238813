import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { Avatar, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Fragment, useState } from "react";
import ProfileQrCode from './ProfileQrCode';
const ProfileDetail = ({ name = "", title = "", email = "", address = "", phone = "" }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Fragment>
            <List
                disablePadding
                sx={{
                    borderRadius: 2,
                    border: 1,
                    borderColor: 'divider',
                }}
            >
                <ListItem>
                    <ListItemAvatar
                        sx={{ minWidth: "64px" }}
                    >
                        <Avatar
                            variant="square"
                            alt={name}
                            src=""
                            sx={{
                                borderRadius: "8px",
                                width: "52px",
                                height: "52px",
                                backgroundColor: '#c0e57b',
                            }}
                        >
                            {name[0]}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={name}
                        secondary={title}
                    />
                    <ListItemIcon
                        sx={{
                            minWidth: "auto",
                            display: "flex",
                        }}>
                        <ListItemButton
                            onClick={handleOpen}
                            sx={{
                                maringTop: 2,
                                p: 0,
                            }}>
                            <QrCodeIcon
                                sx={{
                                    width: "32px",
                                    height: "32px",
                                }}
                            />
                        </ListItemButton>
                    </ListItemIcon>
                </ListItem>

                <ListItem alignItems="flex-start">
                    <ListItemIcon
                        sx={{ minWidth: 30 }}
                    >
                        <LocationOnIcon fontSize="16px" />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{ fontWeight: 'medium', whiteSpace: 'pre-line' }}
                        primary={address ? address : "-"} />
                </ListItem>

                <ListItem alignItems="flex-start">
                    <ListItemIcon
                        sx={{ minWidth: 30 }}
                    >
                        <LocalPhoneIcon fontSize="16px" />
                    </ListItemIcon>
                    <ListItemText
                        primary={phone ? phone : "-"} />
                </ListItem>

                <ListItem alignItems="flex-start">
                    <ListItemIcon
                        sx={{ minWidth: 30 }}
                    >
                        <EmailIcon fontSize="16px" />
                    </ListItemIcon>
                    <ListItemText
                        primary={email ? email : "-"} />
                </ListItem>
            </List>

            <ProfileQrCode
                open={open}
                handleClose={handleClose}
                value={"http://192.168.1.122:3000/login"}
            />
        </Fragment >
    )
}


export default ProfileDetail;