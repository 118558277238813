import { Box, Stack, TableCell, Typography } from "@mui/material";
import ImageWithSkeleton from "../../../component/image/ImageSkeleton";
import CurrencyText from "../../../component/text/CurrencyText";


const DesktopBoard = ({ children }) => {
    return (
        <TableCell sx={{
            textAlign: "center",
            display: {
                xs: "none",
                md: "table-cell"
            }
        }}>
            {children}
        </TableCell>
    )
}

const TextBoard = ({ content, sx = {} }) => {
    return (
        <TableCell sx={{
            textAlign: "center",
            ...sx,
        }}>
            <Typography variant="body2">
                {content}
            </Typography>
        </TableCell>
    )
}

const ImageBoard = ({ imageUrl }) => {
    return (
        <TableCell sx={{ paddingX: 0 }}>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <ImageWithSkeleton
                    imageUrl={imageUrl}
                    aspectRatio={1 / 1}
                    maxWidth="150px"
                    minWidth="100px"
                    width="100%"
                    height="auto"
                />
            </Box>
        </TableCell>
    )
}

const TableDetailBoard = ({ amount, productName }) => {
    return (
        <>
            <TableCell
                sx={{
                    display: {
                        xs: "table-cell",
                        md: "none"
                    }
                }}>
                <Stack spacing={1}>
                    <Box>
                        <Typography
                            textAlign="center"
                            variant="body2"
                        >
                            {productName}
                        </Typography>
                    </Box>
                    <Box>
                        <CurrencyText
                            variant="body2"
                            textAlign="center"
                            amount={amount}
                        />
                    </Box>
                </Stack>
            </TableCell>

            <DesktopBoard>
                <Typography variant="body1">
                    {productName}
                </Typography>
            </DesktopBoard>

            <DesktopBoard>
                <CurrencyText textAlign="center" amount={amount} />
            </DesktopBoard>
        </>
    )
}

const OrderDetailProductItem = ({ number, imageUrl, amount, productName, quantity }) => {
    return (
        <>
            <TextBoard content={`${number}.`} />
            <ImageBoard imageUrl={"https://via.placeholder.com/150"} />
            <TableDetailBoard amount={1000} productName={"產品名稱產品名稱產品名稱產品名稱產品名稱"} />
            <TextBoard sx={{ maxWidth: "20px" }} content={1} />
        </>
    )
}


export default OrderDetailProductItem;