import { TableCell, TableHead, TableRow } from "@mui/material";

const CustomTableCell = ({ children, mobile, constant }) => {
    return (
        <TableCell
            sx={{
                paddingX: 0,
                paddingY: 1,
                textAlign: "center",
                color: "#00A0C8",
                fontWeight: "bold",
                display: {
                    xs: (mobile || constant) ? "table-cell" : "none",
                    md: mobile ? "none" : "table-cell",
                }
            }}>
            {children}
        </TableCell>
    )
}

const OrderDetailProductTitle = () => {
    return (
        <TableHead>
            <TableRow>
                <CustomTableCell constant={true}>項目</CustomTableCell>
                <CustomTableCell constant={true}>商品圖片</CustomTableCell>
                <CustomTableCell>商品名稱</CustomTableCell>
                <CustomTableCell>價格</CustomTableCell>
                <CustomTableCell mobile={true}>商品內容</CustomTableCell>
                <CustomTableCell constant={true}>數量</CustomTableCell>
            </TableRow>
        </TableHead >
    )

}

export default OrderDetailProductTitle;