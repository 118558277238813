// useUserProfile.js
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getProfileApi from '../api/action/getProfileApi';
import { setProfileState } from '../redux/slices/user/slicer';

const useFetchUserProfile = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const fetchUserProfile = useCallback(async (forceRetry) => {
        const response = await getProfileApi();
        if (response.success && (!user.isFetched || forceRetry)) {
            dispatch(setProfileState(response.data));
        }
    }, [dispatch, user.isFetched]);

    useEffect(() => {
        fetchUserProfile();
    }, [fetchUserProfile]);

    return { user, fetchUserProfile };
};

export default useFetchUserProfile;