import { TableCell, TableRow, Typography } from "@mui/material";
import CurrencyText from "../../../component/text/CurrencyText";

const OrderDetailProductSummary = ({ totalAmount, discount, totalQuantity }) => {
    return (
        <>
            <TableRow>
                <TableCell colSpan={2} sx={{ textAlign: "left", fontWeight: "500" }}>
                    <Typography variant="body1">總項目</Typography>
                </TableCell>
                <TableCell colSpan={4} sx={{ textAlign: "right" }}>
                    <Typography variant="body1">{totalQuantity}</Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={2} sx={{ textAlign: "left", fontWeight: "500" }}>
                    <Typography variant="body1">總金額</Typography>
                </TableCell>
                <TableCell colSpan={4} sx={{ textAlign: "right" }}>
                    <CurrencyText textAlign="right" variant="body1" amount={totalAmount} />
                </TableCell>
            </TableRow>
            {
                discount && <TableRow>
                    <TableCell colSpan={2} sx={{ textAlign: "left", fontWeight: "500" }}>
                        <Typography variant="body1">折扣優惠</Typography>
                    </TableCell>
                    <TableCell colSpan={4} sx={{ textAlign: "right" }}>
                        <CurrencyText textAlign="right" variant="body1" amount={discount} />
                    </TableCell>
                </TableRow>
            }
            <TableRow>
                <TableCell colSpan={2} sx={{ textAlign: "left", }}>
                    <Typography variant="body1" fontWeight={"bold"}>總結</Typography>
                </TableCell>
                <TableCell colSpan={4} sx={{ textAlign: "right" }}>
                    <CurrencyText textAlign="right" fontWeight={"bold"} variant="body1" amount={totalAmount - (discount ?? 0)} />
                </TableCell>
            </TableRow>
        </>
    )
}


export default OrderDetailProductSummary;