import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDrawer } from "../../redux/slices/modal/slicer";
import { Drawer } from "@mui/material";
import MenuDrawer from "../../pages/Header/component/NavMenu/MenuDrawer";

const CustomDrawer = () => {
    const open = useSelector(state => state.modal.isDrawerOpen);
    const dispatch = useDispatch();
    const onClose = () => {
        dispatch(closeDrawer());
    }
    return (
        <React.Fragment key={"right"}>
            <Drawer
                sx={{
                    display: {
                        xs: "block",
                        md: "none"
                    },
                    "& .MuiDrawer-paper": {
                        width: "100%",
                        maxWidth: "360px",
                        color: "white"
                    }
                }}
                anchor={"right"}
                open={open}
                onClose={onClose}
            >
                <MenuDrawer onToggle={onClose} />
            </Drawer>
        </React.Fragment>
    )
};
export default CustomDrawer;