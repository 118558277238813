import { Container, List, ListItem, Paper, Typography } from "@mui/material"
const MembershipBoard = () => {
    return (
        <Container maxWidth="lg" sx={{ marginY: 4 }}>
            <Paper
                elevation={3}
                sx={{
                    p: 2,
                    my: 2,
                    mx: 2,
                    textAlign: "center",
                }}
            >
                <Typography variant="h4">會員制度</Typography>
                <List>
                    <ListItem>
                        <Typography>$1 消費 = 1 積分</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>每800積分可以當$10元使用</Typography>
                    </ListItem>
                </List>
            </Paper>
        </Container>
    )
}

export default MembershipBoard;