import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isDrawerOpen: false,
    isCartOpen: false,
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openDrawer: (state) => {
            state.isDrawerOpen = true;
        },
        closeDrawer: (state) => {
            state.isDrawerOpen = false;
        },
        toggleDrawer: (state) => {
            state.isDrawerOpen = !state.isDrawerOpen;
        },
        openCart: (state) => {
            state.isCartOpen = true;
        },
        closeCart: (state) => {
            state.isCartOpen = false;
        },
        toggleCart: (state) => {
            state.isCartOpen = !state.isCartOpen;
        },
    }
});


export const { openDrawer, closeDrawer, toggleDrawer, openCart, closeCart, toggleCart } = modalSlice.actions;
export default modalSlice.reducer;