import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    title: null,
    isToggle: false,
    section: null,
    announcement: null,
    shopMall: {
        isLoaded: false,
        malls: []
    }
};

const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        setTitle: (state, action) => {
            state.title = action.payload;
        },
        setToggle: (state, action) => {
            state.isToggle = action.payload;
        },
        setSection: (state, action) => {
            state.section = action.payload;
        },
        setAnnouncement: (state, action) => {
            state.announcement = action.payload;
        },
        setShopMall: (state, action) => {
            state.shopMall = {
                isLoaded: true,
                malls: action.payload
            };
        }
    },
});

export const { setTitle, setToggle, setSection, setAnnouncement, setShopMall } = headerSlice.actions;
export default headerSlice.reducer;