import { Grid, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import ImageWithSkeleton from "../../../component/image/ImageSkeleton";

const HomeArticle = ({ article }) => {
    return (
        <Grid
            item
            xs={12}
        >
            <Grid
                container
                sx={{
                    backgroundColor: "#fff",
                }}
                alignItems="center"
            >
                <Grid
                    item
                    xs={12}
                    sm={4}>
                    <ImageWithSkeleton
                        imageUrl={article?.image}
                        aspectRatio={{
                            xs: 16 / 9,
                        }}
                        height={{
                            sm: "100%",
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={8}
                    padding={4}
                >
                    <Grid
                        item
                        container
                        direction="column"
                        xs={12}
                        rowSpacing={2}
                    >
                        <Grid item>
                            <Typography variant="h4">{article?.title}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>{article?.description}</Typography>
                        </Grid>
                        <Grid item>
                            <NavLink to={`/article/${article?.id}`}>繼續閱讀</NavLink>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};


export default HomeArticle;