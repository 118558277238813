import { Container, List, ListItem, ListItemText, Typography } from "@mui/material"
import terms from "../../../component/jsonFile/return-term.json"
const ReturnPolicyTerm = () => {
    return (
        <Container maxWidth={"lg"} sx={{ marginY: 6 }}>
            <List sx={{ listStyle: "decimal", pl: 4 }}>
                <Typography textAlign={"center"} variant="h4">
                    退換貨政策
                </Typography>
                {terms.map((term, index) => (
                    <ListItem
                        sx={{ display: "list-item" }}
                        key={index}>
                        <ListItemText primary={term.title} />
                        <List sx={{ listStyle: "outside", pl: 4 }}>
                            {term.content.map((content, index) => (
                                <ListItem key={index} sx={{ display: "list-item" }}>
                                    <Typography> {content}</Typography>
                                </ListItem>
                            ))}
                        </List>
                    </ListItem>
                ))}
            </List>
        </Container>
    )
}

export default ReturnPolicyTerm;