import { useSelector } from 'react-redux';
import AppBarAnnouncement from './component/AppBarAnnouncement';
import AppBarTopMenu from './component/AppBarTopMenu';
import MenuBar from './component/NavMenu/MenuBar';



const Header = () => {
    const isLogin = useSelector(state => state.auth.isLogin);
    return (
        <div className="top-menu">
            <AppBarAnnouncement />
            <AppBarTopMenu isLogin={isLogin} />
            <MenuBar />
        </div>
    )
}


export default Header;