import { Box, Container, Grid } from "@mui/material";
import { Fragment } from "react";
import HomeArticle from "./HomeArticle";

const dumpData = [{
    id: 1,
    title: "海季時節",
    description: "由熱愛海洋的香港藝術家繪畫，繪畫出不同本地常見魚種及海鮮，陪伴大家每一個海季時節。",
    image: "/article.webp"
}, {
    id: 2,
    title: "海季時節",
    description: "由熱愛海洋的香港藝術家繪畫，繪畫出不同本地常見魚種及海鮮，陪伴大家每一個海季時節。",
    image: "/article.webp"
}, {
    id: 3,
    title: "海季時節",
    description: "由熱愛海洋的香港藝術家繪畫，繪畫出不同本地常見魚種及海鮮，陪伴大家每一個海季時節。",
    image: "/article.webp"
},
{
    id: 4,
    title: "海季時節",
    description: "由熱愛海洋的香港藝術家繪畫，繪畫出不同本地常見魚種及海鮮，陪伴大家每一個海季時節。",
    image: "/article.webp"
}]

const HomeArticleSection = () => {

    return (
        <Box
            sx={{
                backgroundColor: "#f5f5f5",
                padding: "36px 0"
            }}
        >
            <Container maxWidth="lg">
                <Grid
                    container
                    rowSpacing={2}
                >
                    {
                        dumpData.map((article) => (
                            <Fragment key={article.id}>
                                <HomeArticle article={article} />
                            </Fragment>
                        ))
                    }
                </Grid>
            </Container>
        </Box>

    )
}

export default HomeArticleSection;