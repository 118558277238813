
export default async function validateCodeApi({ email, code }) {
    console.log("validateCodeApi");

    const response = {
        success: Math.random(8) === 2,
    }
    console.log(response);
    // const api = apiClient;

    // const response = await api.get("/auth/isEmailExist").then(response => {
    //     return {
    //         success: true,
    //         response: response
    //     };
    // }).catch(error => {
    //     return {
    //         success: false,
    //         response: error.response
    //     }
    // });



    return response.success;
};