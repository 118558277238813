import { styled } from '@mui/material';
import ToggleButtonGroup, {
    toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';


const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        border: 0,
        padding: "6px 16px",
        backgroundColor: "#f3f2f2",
        margin: 0,
        [`&.${toggleButtonGroupClasses.disabled}`]: {
            border: 0,
        },
    },
    [`.${toggleButtonGroupClasses.middleButton}`]:
    {
        borderRadius: 0,
    },
    [`.${toggleButtonGroupClasses.firstButton}`]:
    {
        marginRight: 0,
        borderRadius: "8px 0px 0px 8px",
    },
    [`.${toggleButtonGroupClasses.lastButton}`]: {
        borderRadius: "0px 8px 8px 0px",
    },
    [`& .${toggleButtonGroupClasses.selected}`]: {
        boxShadow: "inset 0 0 0 1px #00A0C8",
        backgroundColor: "#dbeaee"
    }
}
));
export default StyledToggleButtonGroup;