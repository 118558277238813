import { Container } from "@mui/material";
import { useCallback, useState } from "react";
import LoadingScreen from "../../component/box/LoadingScreen";
import useProductSearch from "../../hooks/useProductSearch";
import ProductCategorySection from "./component/ProductCategorySection";
import ProductPagination from "./component/ProductPagination";
import ProductSearchSection from "./component/ProductSearchSection";
import ProductViewSection from "./component/ProductViewSection";

const ProductPage = () => {
    const [view, setView] = useState("module");
    const { count, productList, isLoading, page, setPage, handleSearch } = useProductSearch();
    const handleFetch = useCallback((e) => {
        const query = {
            page: page,
            ...e
        };
        handleSearch(query);
    }, [handleSearch, page]);

    return (
        <Container maxWidth="lg" sx={{ marginY: 2 }}>
            <Container maxWidth="lg">
                <ProductSearchSection search={(e) => { handleFetch({ keyword: e }) }} view={view} setView={setView} />
                <ProductCategorySection search={(e) => { handleFetch({ category: e }) }} />
                <LoadingScreen open={isLoading} />
                <ProductViewSection view={view} productList={productList} />
                <ProductPagination count={count} page={page} setPage={setPage} />
            </Container>
        </Container>
    )
}

export default ProductPage