import { Button, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik"
import * as Yup from 'yup';


const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('電子郵件格式錯誤')
        .required('*必須填寫'),
    password: Yup.string().required('*必須填寫'),
});

const ForgetForm = ({ handleFormSubmit }) => {
    return (
        <Formik
            initialValues={{ email: '' }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
        >
            {({
                handleSubmit,
                touched,
                errors,
                handleChange,
                handleBlur
            }) => (
                <Form onSubmit={handleSubmit}>
                    <Typography variant="body2" fontWeight={700} sx={{ mb: 2 }}>
                        請輸入您的電郵地址
                    </Typography>
                    <TextField
                        label="電郵"
                        variant="outlined"
                        name="email"
                        fullWidth
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        sx={{ mb: 2 }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                    >
                        重設密碼
                    </Button>
                </Form>
            )}
        </Formik>
    )
}

export default ForgetForm